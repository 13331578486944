import { AIServiceError } from '../errors/AIServiceError';
import { AI_CONFIG } from '../config';
import { withRetry } from '../retry/withRetry';
import { OPENAI_API_KEY } from '../../config';

interface OpenAIResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

async function makeRequest(prompt: string): Promise<string> {
  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: AI_CONFIG.model,
      messages: [{ role: 'user', content: prompt }],
      temperature: AI_CONFIG.temperature,
      max_tokens: AI_CONFIG.maxTokens,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    
    if (response.status === 429) {
      // Special handling for rate limits
      await new Promise(resolve => setTimeout(resolve, AI_CONFIG.rateLimitDelay));
      throw new AIServiceError({
        code: 'RATE_LIMIT',
        message: 'API rate limit exceeded. Retrying after cooldown.',
        retryable: true,
        cause: errorData
      });
    }

    throw new AIServiceError({
      code: 'API_ERROR',
      message: errorData.error?.message || `API error: ${response.statusText}`,
      retryable: response.status >= 500,
      cause: errorData
    });
  }

  const data = await response.json() as OpenAIResponse;
  if (!data.choices?.[0]?.message?.content) {
    throw new AIServiceError({
      code: 'INVALID_RESPONSE',
      message: 'Invalid response from OpenAI API',
      retryable: true
    });
  }

  return data.choices[0].message.content;
}

export async function callOpenAI(prompt: string): Promise<string> {
  if (!OPENAI_API_KEY) {
    throw new AIServiceError({
      code: 'CONFIG_ERROR',
      message: 'OpenAI API key is not configured',
      retryable: false
    });
  }

  return withRetry(
    () => makeRequest(prompt),
    {
      maxRetries: AI_CONFIG.maxRetries,
      baseDelay: AI_CONFIG.baseDelay,
      maxDelay: AI_CONFIG.maxDelay,
      shouldRetry: (error: Error) => {
        if (error instanceof AIServiceError) {
          return error.retryable;
        }
        return false;
      }
    }
  );
}