import type { AIErrorOptions } from './types';

export class AIServiceError extends Error {
  readonly code: string;
  readonly retryable: boolean;
  
  constructor(options: AIErrorOptions) {
    super(options.message);
    this.name = 'AIServiceError';
    this.code = options.code;
    this.retryable = options.retryable;
    this.cause = options.cause;
  }

  static isAIServiceError(error: unknown): error is AIServiceError {
    return error instanceof AIServiceError;
  }
}