import React from 'react';
import { Zap, Clock, AlertTriangle, TrendingUp } from 'lucide-react';

export default function AIInsights() {
  // Sample insights - in real app, these would be generated by AI
  const insights = [
    {
      icon: <Clock className="w-4 h-4 text-green-500" />,
      title: 'Peak Productivity',
      description: 'Your most productive hours are between 9 AM and 11 AM'
    },
    {
      icon: <AlertTriangle className="w-4 h-4 text-yellow-500" />,
      title: 'Meeting Load',
      description: 'Thursday meetings exceed recommended daily limit'
    },
    {
      icon: <TrendingUp className="w-4 h-4 text-indigo-500" />,
      title: 'Task Completion',
      description: '23% increase in task completion rate this week'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {insights.map((insight, index) => (
          <div key={index} className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg">
            <div className="mt-0.5">{insight.icon}</div>
            <div>
              <h3 className="text-sm font-medium text-gray-900">{insight.title}</h3>
              <p className="text-sm text-gray-600">{insight.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="border-t pt-4">
        <button className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-700">
          <Zap className="w-4 h-4" />
          <span>Generate New Insights</span>
        </button>
      </div>
    </div>
  );
}