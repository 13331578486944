import React from 'react';
import { Brain } from 'lucide-react';
import type { DreamCategory } from '../../types/dreams';
import VoiceInput from '../voice/VoiceInput';

interface DreamContentInputProps {
  value: string;
  onChange: (content: string) => void;
  category: DreamCategory;
}

export default function DreamContentInput({ value, onChange, category }: DreamContentInputProps) {
  const handleVoiceInput = (text: string) => {
    onChange(value + ' ' + text);
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <Brain className="w-5 h-5 text-indigo-600" />
          <label htmlFor="dream-content" className="text-sm font-medium text-gray-700">
            {category === 'sleep' ? 'Share Your Dream' : 'Describe Your Aspiration'}
          </label>
        </div>
        <VoiceInput onTextChange={handleVoiceInput} />
      </div>
      <textarea
        id="dream-content"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={category === 'sleep' 
          ? "Describe your dream journey..."
          : "Describe your aspirational dream..."}
        rows={6}
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm sm:text-base"
        required
      />
    </div>
  );
}