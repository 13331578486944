import { callOpenAI } from '../service';
import { DREAM_ANALYSIS_PROMPT } from './prompts';
import type { Dream } from '../../../types/dreams';
import type { DreamAnalysisResponse, GenerateInsightsOptions } from './types';
import { AIServiceError } from '../errors/AIServiceError';

export async function generateDreamInsights(
  dreams: Dream[],
  options?: GenerateInsightsOptions
): Promise<DreamAnalysisResponse> {
  if (!dreams.length) {
    throw new AIServiceError({
      code: 'VALIDATION_ERROR',
      message: 'No dreams provided for analysis',
      retryable: false
    });
  }

  try {
    // Format dreams for analysis
    const dreamSummaries = dreams.map(dream => ({
      title: dream.title,
      content: dream.content,
      mood: dream.mood
    }));

    // Get AI analysis
    const response = await callOpenAI(
      `${DREAM_ANALYSIS_PROMPT}\n\n${JSON.stringify(dreamSummaries)}`
    );

    let insights: DreamAnalysisResponse;
    try {
      insights = JSON.parse(response);
    } catch (error) {
      throw new AIServiceError({
        code: 'PARSE_ERROR',
        message: 'Failed to parse AI response',
        retryable: true
      });
    }

    // Validate response structure
    if (!insights.summary || !Array.isArray(insights.patterns) || !Array.isArray(insights.recommendations)) {
      throw new AIServiceError({
        code: 'INVALID_RESPONSE',
        message: 'Invalid insights data received',
        retryable: true
      });
    }

    return insights;
  } catch (error) {
    if (error instanceof AIServiceError) throw error;
    
    throw new AIServiceError({
      code: 'UNKNOWN_ERROR',
      message: 'Failed to generate insights',
      retryable: true,
      cause: error
    });
  }
}