import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import LoadingState from '../components/LoadingState';

export default function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthComplete = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) throw error;
        
        if (session) {
          navigate('/profile', { replace: true });
        } else {
          navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/login', { replace: true });
      }
    };

    handleAuthComplete();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <LoadingState message="Completing login..." />
    </div>
  );
}