import React, { useState, useEffect } from 'react';
import { Settings, AlertCircle, CheckCircle2 } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { googleAuthService } from '../../lib/services/auth/GoogleAuthService';
import { jiraAuthService } from '../../lib/services/auth/JiraAuthService';
import { notionAuthService } from '../../lib/services/auth/NotionAuthService';
import { outlookAuthService } from '../../lib/services/auth/OutlookAuthService';
import ServiceCard from './ServiceCard';
import { ErrorAlert } from '../../components/ui/ErrorAlert';

export default function ServiceIntegration() {
  const [integrationStatus, setIntegrationStatus] = useState({
    jira: false,
    notion: false,
    googleCalendar: false,
    outlookCalendar: false
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadIntegrationStatus();
  }, []);

  const loadIntegrationStatus = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError('Please sign in to connect services');
        setLoading(false);
        return;
      }

      const { data: integrations, error: dbError } = await supabase
        .from('user_integrations')
        .select('service')
        .eq('user_id', user.id);

      if (dbError) throw dbError;

      const status = {
        jira: false,
        notion: false,
        googleCalendar: false,
        outlookCalendar: false
      };

      if (integrations) {
        integrations.forEach(integration => {
          if (integration.service === 'jira') status.jira = true;
          if (integration.service === 'notion') status.notion = true;
          if (integration.service === 'google_calendar') status.googleCalendar = true;
          if (integration.service === 'outlook_calendar') status.outlookCalendar = true;
        });
      }

      setIntegrationStatus(status);
    } catch (error) {
      console.error('Failed to load integration status:', error);
      setError('Failed to load integration status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async (service: keyof typeof integrationStatus) => {
    setError(null);
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError('Please sign in to connect services');
        return;
      }

      switch (service) {
        case 'jira':
          await jiraAuthService.initiateAuth();
          break;
        case 'notion':
          await notionAuthService.initiateAuth();
          break;
        case 'googleCalendar':
          await googleAuthService.initiateAuth();
          break;
        case 'outlookCalendar':
          await outlookAuthService.initiateAuth();
          break;
        default:
          setError(`Service ${service} not implemented`);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to connect service';
      setError(message);
      console.error(`Failed to connect to ${service}:`, error);
    }
  };

  const handleDisconnect = async (service: keyof typeof integrationStatus) => {
    setError(null);
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError('Please sign in to disconnect services');
        return;
      }

      const serviceMap = {
        googleCalendar: 'google_calendar',
        outlookCalendar: 'outlook_calendar',
        jira: 'jira',
        notion: 'notion'
      };

      const { error: dbError } = await supabase
        .from('user_integrations')
        .delete()
        .match({ 
          user_id: user.id, 
          service: serviceMap[service]
        });

      if (dbError) throw dbError;

      setIntegrationStatus(prev => ({ ...prev, [service]: false }));
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to disconnect service';
      setError(message);
      console.error(`Failed to disconnect from ${service}:`, error);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {[1, 2, 3, 4].map(i => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Settings className="w-5 h-5 text-indigo-600" />
          <h2 className="text-lg font-semibold">Service Integrations</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="flex items-center gap-1">
            <CheckCircle2 className="w-4 h-4 text-green-500" />
            <span className="text-gray-600">Connected</span>
          </div>
          <div className="flex items-center gap-1">
            <AlertCircle className="w-4 h-4 text-gray-400" />
            <span className="text-gray-600">Not Connected</span>
          </div>
        </div>
      </div>

      {error && (
        <div className="mb-4">
          <ErrorAlert message={error} onClose={() => setError(null)} />
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ServiceCard
          name="Jira"
          description="Track and manage project tasks"
          isConnected={integrationStatus.jira}
          onConnect={() => handleConnect('jira')}
          onDisconnect={() => handleDisconnect('jira')}
          icon="https://cdn.worldvectorlogo.com/logos/jira-1.svg"
        />

        <ServiceCard
          name="Notion"
          description="Organize notes and documents"
          isConnected={integrationStatus.notion}
          onConnect={() => handleConnect('notion')}
          onDisconnect={() => handleDisconnect('notion')}
          icon="https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
        />

        <ServiceCard
          name="Google Calendar"
          description="Manage events and meetings"
          isConnected={integrationStatus.googleCalendar}
          onConnect={() => handleConnect('googleCalendar')}
          onDisconnect={() => handleDisconnect('googleCalendar')}
          icon="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"
        />

        <ServiceCard
          name="Outlook Calendar"
          description="Sync Microsoft calendar events"
          isConnected={integrationStatus.outlookCalendar}
          onConnect={() => handleConnect('outlookCalendar')}
          onDisconnect={() => handleDisconnect('outlookCalendar')}
          icon="https://upload.wikimedia.org/wikipedia/commons/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg"
        />
      </div>
    </div>
  );
}