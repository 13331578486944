import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useAlarms } from '../../../hooks/useAlarms';
import AlarmHeader from './AlarmHeader';
import AlarmTabs from './AlarmTabs';
import AnimatedBackground from '../../animations/AnimatedBackground';
import LoadingState from '../../ui/LoadingState';
import { ErrorAlert } from '../../ui/ErrorAlert';
import { getDreamThemes } from '../../../lib/alarms/analysis';

export default function AlarmDashboard() {
  const { user } = useAuth();
  const { alarms, loading, error, toggleAlarm, removeAlarm } = useAlarms();
  const [activeTab, setActiveTab] = useState<'active' | 'inactive' | 'all'>('active');

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
        <AnimatedBackground type="neural" opacity="low" />
        <div className="max-w-7xl mx-auto px-4 py-4 sm:py-8 relative z-10">
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Please Sign In</h2>
            <p className="text-gray-600">You need to be signed in to view and manage alarms.</p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <LoadingState message="Loading your dream alarms..." />;
  }

  if (error) {
    return <ErrorAlert message={error.message} />;
  }

  const filteredAlarms = alarms.filter(alarm => {
    switch (activeTab) {
      case 'active':
        return alarm.active;
      case 'inactive':
        return !alarm.active;
      default:
        return true;
    }
  });

  const statistics = {
    totalAlarms: alarms.length,
    activeAlarms: alarms.filter(a => a.active).length,
    themes: getDreamThemes(alarms)
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
      <AnimatedBackground type="neural" opacity="low" />
      
      <div className="max-w-7xl mx-auto px-4 py-4 sm:py-8 relative z-10">
        <AlarmHeader statistics={statistics} />
        <div className="mt-4 sm:mt-8">
          <AlarmTabs
            alarms={filteredAlarms}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            onToggle={toggleAlarm}
            onDelete={removeAlarm}
            statistics={statistics}
          />
        </div>
      </div>
    </div>
  );
}