import React from 'react';
import { Bell, BellOff, Trash2, ExternalLink } from 'lucide-react';
import type { DreamAlarm } from '../../types/alarms';

interface AlarmCardProps {
  alarm: DreamAlarm;
  onToggle: (id: string, active: boolean) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onViewDream?: () => void;
}

export default function AlarmCard({ 
  alarm, 
  onToggle, 
  onDelete,
  onViewDream
}: AlarmCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-4">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
        <div className="flex-1 min-w-0">
          <div className="flex flex-col sm:flex-row sm:items-center gap-2 mb-2">
            <span className="text-lg font-medium">{alarm.time}</span>
            <span className={`text-sm px-2 py-0.5 rounded-full w-fit ${
              alarm.tone === 'calming' ? 'bg-blue-100 text-blue-700' :
              alarm.tone === 'energizing' ? 'bg-yellow-100 text-yellow-700' :
              'bg-gray-100 text-gray-700'
            }`}>
              {alarm.tone}
            </span>
          </div>

          {alarm.dreams && (
            <button
              onClick={onViewDream}
              className="mt-1 text-left group w-full sm:w-auto"
            >
              <span className="text-indigo-600 hover:text-indigo-800 font-medium inline-flex items-center text-sm">
                {alarm.dreams.title}
                <ExternalLink className="w-4 h-4 ml-1 opacity-0 group-hover:opacity-100 transition-opacity" />
              </span>
              {alarm.dreams.mood && (
                <span className="ml-2 text-xs text-gray-500">
                  Mood: {alarm.dreams.mood}
                </span>
              )}
            </button>
          )}

          <p className="text-gray-600 mt-2 text-sm">{alarm.message}</p>
        </div>

        <div className="flex items-center gap-2 w-full sm:w-auto">
          <button
            onClick={() => onToggle(alarm.id, !alarm.active)}
            className={`flex-1 sm:flex-none flex items-center justify-center gap-2 p-2 rounded-full transition-colors ${
              alarm.active
                ? 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
                : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
            }`}
            title={alarm.active ? 'Disable alarm' : 'Enable alarm'}
          >
            {alarm.active ? (
              <Bell className="w-5 h-5" />
            ) : (
              <BellOff className="w-5 h-5" />
            )}
          </button>

          <button
            onClick={() => onDelete(alarm.id)}
            className="flex-1 sm:flex-none flex items-center justify-center gap-2 p-2 rounded-full text-red-600 hover:bg-red-50 transition-colors"
            title="Delete alarm"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}