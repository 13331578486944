import { supabase } from '../../supabase';

const OUTLOOK_CLIENT_ID = import.meta.env.VITE_OUTLOOK_CLIENT_ID;
const OUTLOOK_REDIRECT_URI = `${window.location.origin}/auth/outlook/callback`;
const OUTLOOK_SCOPES = [
  'Calendars.Read',
  'Calendars.ReadWrite',
  'offline_access'
].join(' ');

export class OutlookAuthService {
  private static instance: OutlookAuthService;

  private constructor() {}

  static getInstance(): OutlookAuthService {
    if (!OutlookAuthService.instance) {
      OutlookAuthService.instance = new OutlookAuthService();
    }
    return OutlookAuthService.instance;
  }

  async initiateAuth() {
    if (!OUTLOOK_CLIENT_ID) {
      throw new Error('Outlook Client ID not configured');
    }

    const state = crypto.randomUUID();
    localStorage.setItem('outlookOAuthState', state);

    const params = new URLSearchParams({
      client_id: OUTLOOK_CLIENT_ID,
      redirect_uri: OUTLOOK_REDIRECT_URI,
      response_type: 'code',
      scope: OUTLOOK_SCOPES,
      state,
      response_mode: 'query'
    });

    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`;
  }

  async handleCallback(code: string, state: string): Promise<void> {
    const storedState = localStorage.getItem('outlookOAuthState');
    localStorage.removeItem('outlookOAuthState');

    if (!storedState || state !== storedState) {
      throw new Error('Invalid state parameter');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { error } = await supabase
        .from('user_integrations')
        .upsert({
          user_id: user.id,
          service: 'outlook_calendar',
          auth_code: code,
          connected_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Failed to handle Outlook OAuth callback:', error);
      throw error;
    }
  }
}

export const outlookAuthService = OutlookAuthService.getInstance();