import React from 'react';
import { Clock } from 'lucide-react';

interface TimeInputProps {
  value: string;
  onChange: (time: string) => void;
}

export default function TimeInput({ value, onChange }: TimeInputProps) {
  return (
    <div>
      <label htmlFor="alarm-time" className="block text-sm font-medium text-gray-700 mb-1">
        Alarm Time
      </label>
      <div className="relative">
        <Clock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
        <input
          type="time"
          id="alarm-time"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base sm:text-sm"
          required
        />
      </div>
    </div>
  );
}