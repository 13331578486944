import React from 'react';
import { Moon, Target, Calendar, Brain } from 'lucide-react';

const steps = [
  {
    icon: <Moon className="h-6 w-6" />,
    title: 'Record Dreams & Aspirations',
    description: 'Share your sleep dreams and life goals in a secure, private space for AI analysis.'
  },
  {
    icon: <Brain className="h-6 w-6" />,
    title: 'Get Deep Insights',
    description: 'Receive AI-powered interpretation of your dreams and strategic planning for your goals.'
  },
  {
    icon: <Target className="h-6 w-6" />,
    title: 'Create Action Plans',
    description: 'Transform insights into concrete steps with smart task management and tracking.'
  },
  {
    icon: <Calendar className="h-6 w-6" />,
    title: 'Boost Productivity',
    description: 'Integrate with Jira, Notion, and calendars to seamlessly execute your plans.'
  }
];

export default function HowItWorks() {
  return (
    <div className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Your Journey to Dream Realization
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            From subconscious insights to real-world achievements
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
            {steps.map((step, index) => (
              <div key={index} className="text-center relative">
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-8 left-1/2 w-full h-0.5 bg-indigo-200"></div>
                )}
                <div className="relative">
                  <div className="flex items-center justify-center h-16 w-16 rounded-full bg-indigo-500 text-white mx-auto">
                    {step.icon}
                  </div>
                  <h3 className="mt-4 text-lg font-medium text-gray-900">
                    {step.title}
                  </h3>
                  <p className="mt-2 text-base text-gray-500">
                    {step.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}