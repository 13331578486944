interface RetryOptions {
  maxRetries?: number;
  baseDelay?: number;
  maxDelay?: number;
  shouldRetry?: (error: Error) => boolean;
}

const DEFAULT_OPTIONS: Required<RetryOptions> = {
  maxRetries: 3,
  baseDelay: 1000,
  maxDelay: 5000,
  shouldRetry: (error: Error) => {
    const message = error.message.toLowerCase();
    return message.includes('failed to fetch') ||
           message.includes('network') ||
           message.includes('timeout') ||
           message.includes('rate limit') ||
           message.includes('connection');
  }
};

export async function withRetry<T>(
  operation: () => Promise<T>,
  options: RetryOptions = {}
): Promise<T> {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  let lastError: Error;
  let attempt = 0;
  
  while (attempt < opts.maxRetries) {
    try {
      return await operation();
    } catch (error) {
      lastError = error instanceof Error ? error : new Error(String(error));
      
      if (!opts.shouldRetry(lastError) || attempt === opts.maxRetries - 1) {
        throw lastError;
      }
      
      const delay = Math.min(
        opts.baseDelay * Math.pow(2, attempt),
        opts.maxDelay
      );
      
      console.debug(`Retry attempt ${attempt + 1}/${opts.maxRetries} after ${delay}ms`, { 
        error: lastError.message 
      });
      
      await new Promise(resolve => setTimeout(resolve, delay));
      attempt++;
    }
  }
  
  throw lastError!;
}