import React from 'react';
import { Mail, Github, Linkedin, ExternalLink } from 'lucide-react';

export default function Developer() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Developer Information</h1>
        
        <div className="prose prose-indigo max-w-none">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">About the Developer</h2>
            <p className="text-gray-600 leading-relaxed">
              DreamAlarm is developed and maintained by Hariom, a passionate full-stack developer 
              with expertise in building scalable web applications and cloud infrastructure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Information</h2>
            <div className="space-y-3">
              <a 
                href="mailto:hariom.devops@gmail.com"
                className="flex items-center gap-2 text-indigo-600 hover:text-indigo-800"
              >
                <Mail className="w-5 h-5" />
                <span>hariom.devops@gmail.com</span>
              </a>
              
              <a 
                href="https://github.com/yourusername"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-indigo-600 hover:text-indigo-800"
              >
                <Github className="w-5 h-5" />
                <span>GitHub</span>
                <ExternalLink className="w-4 h-4" />
              </a>
              
              <a 
                href="https://linkedin.com/in/yourusername"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-indigo-600 hover:text-indigo-800"
              >
                <Linkedin className="w-5 h-5" />
                <span>LinkedIn</span>
                <ExternalLink className="w-4 h-4" />
              </a>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Technologies Used</h2>
            <ul className="list-disc pl-6 space-y-2 text-gray-600">
              <li>React with TypeScript</li>
              <li>Supabase for Backend and Authentication</li>
              <li>Tailwind CSS for Styling</li>
              <li>OpenAI API for Dream Analysis</li>
              <li>Vite for Build Tooling</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Contributions</h2>
            <p className="text-gray-600 leading-relaxed">
              DreamAlarm is an open-source project. Contributions, bug reports, and feature requests 
              are welcome on our GitHub repository.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}