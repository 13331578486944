import { supabase } from '../supabase';
import { withRetry } from '../utils/retry';
import { requireAuth } from './auth';
import type { DreamAlarm, CreateAlarmData } from '../../types/alarms';

export async function getUserAlarms(): Promise<DreamAlarm[]> {
  return withRetry(async () => {
    try {
      const user = await requireAuth();

      const { data, error } = await supabase
        .from('dream_alarms')
        .select(`
          *,
          dreams (
            title,
            mood,
            dream_interpretations (
              interpretation
            )
          )
        `)
        .eq('user_id', user.id)
        .order('time', { ascending: true });

      if (error) throw error;
      return data || [];
    } catch (error) {
      if (error instanceof Error && error.message === 'Authentication required') {
        return []; // Return empty array for unauthenticated users
      }
      throw error;
    }
  });
}