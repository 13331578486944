import { supabase } from '../supabase';
import { withRetry } from '../utils/retry';
import { handleSupabaseError } from '../utils/errors/handlers';
import { DreamError } from '../utils/errors/DreamError';
import type { Dream } from '../../types/dreams';

export async function deleteDream(dreamId: string): Promise<void> {
  return withRetry(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new DreamError('Must be logged in to delete dreams');

      // First verify user owns the dream
      const { data: dream, error: fetchError } = await supabase
        .from('dreams')
        .select('id, user_id')
        .eq('id', dreamId)
        .single();

      if (fetchError) throw fetchError;
      if (!dream) throw new DreamError('Dream not found');
      if (dream.user_id !== user.id) throw new DreamError('Access denied');

      // Delete the dream (cascade will handle related records)
      const { error: deleteError } = await supabase
        .from('dreams')
        .delete()
        .eq('id', dreamId)
        .eq('user_id', user.id); // Extra safety check

      if (deleteError) throw deleteError;
    } catch (error) {
      console.error('Dream deletion failed:', error);
      throw handleSupabaseError(error);
    }
  });
}

export async function updateDreamVisibility(dreamId: string, isPrivate: boolean): Promise<Dream> {
  return withRetry(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new DreamError('Must be logged in to update dreams');

      // Update dream visibility
      const { data, error } = await supabase
        .from('dreams')
        .update({ is_private: isPrivate })
        .eq('id', dreamId)
        .eq('user_id', user.id) // Ensure user owns the dream
        .select(`
          *,
          dream_interpretations (
            interpretation,
            symbols_analysis,
            is_plan
          )
        `)
        .single();

      if (error) throw error;
      if (!data) throw new DreamError('Dream not found');

      return data;
    } catch (error) {
      console.error('Dream visibility update failed:', error);
      throw handleSupabaseError(error);
    }
  });
}

export async function getUserDreams(userId: string): Promise<Dream[]> {
  return withRetry(async () => {
    try {
      const { data, error } = await supabase
        .from('dreams')
        .select(`
          *,
          dream_interpretations (
            interpretation,
            symbols_analysis,
            is_plan
          )
        `)
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('Failed to fetch dreams:', error);
      throw handleSupabaseError(error);
    }
  });
}