import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { generateDreamInsights } from '../lib/ai/dreamAnalysis/generateInsights';
import type { Dream, DreamInsight } from '../types/dreams';
import { AIServiceError } from '../lib/ai/errors/AIServiceError';

export function useDreamInsights(userId: string | undefined, dreams: Dream[]) {
  const [insights, setInsights] = useState<DreamInsight | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId || !dreams.length) {
      setLoading(false);
      return;
    }
    loadInsights();
  }, [userId, dreams]);

  async function loadInsights() {
    try {
      const { data, error: dbError } = await supabase
        .from('dream_insights')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false })
        .limit(1);

      if (dbError) throw dbError;
      
      if (data && data.length > 0) {
        setInsights(data[0]);
      } else {
        // No existing insights, generate new ones
        await regenerateInsights();
      }
    } catch (err) {
      console.error('Error loading insights:', err);
      setError(err instanceof Error ? err : new Error('Failed to load insights'));
    } finally {
      setLoading(false);
    }
  }

  async function regenerateInsights() {
    if (!userId || !dreams.length) return;

    setLoading(true);
    setError(null);

    try {
      const analysisResult = await generateDreamInsights(dreams);
      
      const { data, error: dbError } = await supabase
        .from('dream_insights')
        .insert({
          user_id: userId,
          summary: analysisResult.summary,
          patterns: analysisResult.patterns,
          recommendations: analysisResult.recommendations
        })
        .select()
        .single();

      if (dbError) throw dbError;
      setInsights(data);
    } catch (err) {
      const error = err instanceof AIServiceError ? err : new Error('Failed to generate insights');
      console.error('Error generating insights:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return {
    insights,
    loading,
    error,
    regenerateInsights
  };
}