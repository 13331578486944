import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import UpdatePassword from './pages/UpdatePassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Developer from './pages/Developer';
import Alarms from './pages/Alarms';
import Productivity from './pages/Productivity';
import GoogleCallback from './pages/auth/GoogleCallback';
import JiraCallback from './pages/auth/JiraCallback';
import NotionCallback from './pages/auth/NotionCallback';
import OutlookCallback from './pages/auth/OutlookCallback';
import PrivateRoute from './components/PrivateRoute';
import AuthCallback from './pages/AuthCallback';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="/auth/jira/callback" element={<JiraCallback />} />
        <Route path="/auth/notion/callback" element={<NotionCallback />} />
        <Route path="/auth/outlook/callback" element={<OutlookCallback />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/developer" element={<Developer />} />
          <Route path="/productivity" element={<Productivity />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/alarms"
            element={
              <PrivateRoute>
                <Alarms />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;