import { supabase } from '../../supabase';

const JIRA_CLIENT_ID = import.meta.env.VITE_JIRA_CLIENT_ID;
const JIRA_REDIRECT_URI = `${window.location.origin}/auth/jira/callback`;

export class JiraAuthService {
  private static instance: JiraAuthService;

  private constructor() {}

  static getInstance(): JiraAuthService {
    if (!JiraAuthService.instance) {
      JiraAuthService.instance = new JiraAuthService();
    }
    return JiraAuthService.instance;
  }

  async initiateAuth() {
    if (!JIRA_CLIENT_ID) {
      throw new Error('Jira Client ID not configured');
    }

    const state = crypto.randomUUID();
    localStorage.setItem('jiraOAuthState', state);

    const params = new URLSearchParams({
      client_id: JIRA_CLIENT_ID,
      redirect_uri: JIRA_REDIRECT_URI,
      response_type: 'code',
      state,
      scope: 'read:jira-work write:jira-work',
      prompt: 'consent'
    });

    window.location.href = `https://auth.atlassian.com/authorize?${params.toString()}`;
  }

  async handleCallback(code: string, state: string): Promise<void> {
    const storedState = localStorage.getItem('jiraOAuthState');
    localStorage.removeItem('jiraOAuthState');

    if (!storedState || state !== storedState) {
      throw new Error('Invalid state parameter');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      // Store the auth code
      const { error } = await supabase
        .from('user_integrations')
        .upsert({
          user_id: user.id,
          service: 'jira',
          auth_code: code,
          connected_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Failed to handle Jira OAuth callback:', error);
      throw error;
    }
  }
}

export const jiraAuthService = JiraAuthService.getInstance();