import { supabase } from '../../supabase';

const NOTION_CLIENT_ID = import.meta.env.VITE_NOTION_CLIENT_ID;
const NOTION_CLIENT_SECRET = import.meta.env.VITE_NOTION_CLIENT_SECRET;
const NOTION_REDIRECT_URI = `${window.location.origin}/auth/notion/callback`;
const NOTION_AUTH_URL = 'https://api.notion.com/v1/oauth/authorize';
const NOTION_TOKEN_URL = 'https://api.notion.com/v1/oauth/token';

export class NotionAuthService {
  private static instance: NotionAuthService;

  private constructor() {}

  static getInstance(): NotionAuthService {
    if (!NotionAuthService.instance) {
      NotionAuthService.instance = new NotionAuthService();
    }
    return NotionAuthService.instance;
  }

  async initiateAuth() {
    if (!NOTION_CLIENT_ID) {
      throw new Error('Notion integration is not properly configured');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Authentication required');

      const state = crypto.randomUUID();
      localStorage.setItem('notionOAuthState', state);

      const params = new URLSearchParams({
        client_id: NOTION_CLIENT_ID,
        redirect_uri: NOTION_REDIRECT_URI,
        response_type: 'code',
        owner: 'user',
        state
      });

      window.location.href = `${NOTION_AUTH_URL}?${params.toString()}`;
    } catch (error) {
      console.error('Notion OAuth initiation failed:', error);
      throw error;
    }
  }

  async handleCallback(code: string, state: string): Promise<void> {
    const storedState = localStorage.getItem('notionOAuthState');
    localStorage.removeItem('notionOAuthState');

    if (!storedState || state !== storedState) {
      throw new Error('Invalid state parameter');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Authentication required');

      // Exchange code for token
      const basicAuth = btoa(`${NOTION_CLIENT_ID}:${NOTION_CLIENT_SECRET}`);
      const tokenResponse = await fetch(NOTION_TOKEN_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Basic ${basicAuth}`,
          'Notion-Version': '2022-06-28'
        },
        body: JSON.stringify({
          grant_type: 'authorization_code',
          code,
          redirect_uri: NOTION_REDIRECT_URI
        })
      });

      if (!tokenResponse.ok) {
        const errorData = await tokenResponse.json();
        console.error('Notion token exchange failed:', errorData);
        throw new Error(errorData.error_description || 'Failed to exchange authorization code');
      }

      const tokenData = await tokenResponse.json();

      // Store the integration data in Supabase
      const { error: dbError } = await supabase
        .from('user_integrations')
        .upsert({
          user_id: user.id,
          service: 'notion',
          access_token: tokenData.access_token,
          workspace_id: tokenData.workspace_id,
          bot_id: tokenData.bot_id,
          connected_at: new Date().toISOString()
        });

      if (dbError) {
        console.error('Failed to store Notion integration:', dbError);
        throw new Error('Failed to save integration data');
      }
    } catch (error) {
      console.error('Notion OAuth callback failed:', error);
      throw error;
    }
  }
}

export const notionAuthService = NotionAuthService.getInstance();