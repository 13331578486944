import React from 'react';
import type { Comment } from '../../lib/comments/types';
import AIComment from './AIComment';
import CommentDisplay from './CommentDisplay';

interface CommentListProps {
  comments: Comment[];
}

export default function CommentList({ comments }: CommentListProps) {
  // Sort comments: AI comments first, then user comments by date
  const sortedComments = [...comments].sort((a, b) => {
    if (a.is_ai && !b.is_ai) return -1;
    if (!a.is_ai && b.is_ai) return 1;
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <div className="space-y-4 mb-4">
      {sortedComments.map((comment) => (
        comment.is_ai ? (
          <AIComment key={comment.id} comment={comment} />
        ) : (
          <CommentDisplay key={comment.id} comment={comment} />
        )
      ))}
    </div>
  );
}