import { supabase } from '../supabase';
import type { User } from '@supabase/supabase-js';

export async function getCurrentUser(): Promise<User | null> {
  try {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) throw error;
    return user;
  } catch (error) {
    console.debug('No authenticated user found');
    return null;
  }
}

export function requireAuth(): Promise<User> {
  return getCurrentUser().then(user => {
    if (!user) {
      throw new Error('Authentication required');
    }
    return user;
  });
}