import React, { useState } from 'react';
import { Clock } from 'lucide-react';
import type { DreamAlarm } from '../../types/alarms';
import AlarmCard from './AlarmCard';
import DreamDetails from '../DreamDetails';

interface AlarmListProps {
  alarms: DreamAlarm[];
  onToggle: (id: string, active: boolean) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

export default function AlarmList({ alarms, onToggle, onDelete }: AlarmListProps) {
  const [selectedDreamId, setSelectedDreamId] = useState<string | null>(null);

  if (!alarms.length) {
    return (
      <div className="text-center py-8">
        <Clock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">No Alarms Found</h3>
        <p className="text-gray-600 text-sm">
          Create your first alarm to start managing your dream reminders.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {alarms.map(alarm => (
        <AlarmCard
          key={alarm.id}
          alarm={alarm}
          onToggle={onToggle}
          onDelete={onDelete}
          onViewDream={alarm.dream_id ? () => setSelectedDreamId(alarm.dream_id!) : undefined}
        />
      ))}

      {selectedDreamId && (
        <DreamDetails
          dreamId={selectedDreamId}
          onClose={() => setSelectedDreamId(null)}
          onDelete={async () => {
            // No delete functionality needed in this context
            setSelectedDreamId(null);
          }}
          onVisibilityToggle={async () => {
            // No visibility toggle needed in this context
            setSelectedDreamId(null);
          }}
        />
      )}
    </div>
  );
}