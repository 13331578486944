import type { DreamAlarm } from '../../types/alarms';

export function getDreamThemes(alarms: DreamAlarm[]): string[] {
  const themes = new Set<string>();
  
  alarms.forEach(alarm => {
    if (alarm.dreams?.content) {
      // Extract themes based on common keywords
      const content = alarm.dreams.content.toLowerCase();
      if (content.includes('achieve') || content.includes('goal')) themes.add('Ambition');
      if (content.includes('create') || content.includes('art')) themes.add('Creativity');
      if (content.includes('learn') || content.includes('study')) themes.add('Growth');
      if (content.includes('love') || content.includes('relationship')) themes.add('Relationships');
      if (content.includes('work') || content.includes('career')) themes.add('Career');
    }
  });

  return Array.from(themes);
}