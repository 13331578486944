import React, { useState } from 'react';
import { Bell } from 'lucide-react';
import type { CreateAlarmData } from '../../types/alarms';
import TimeInput from './TimeInput';
import MessageInput from './MessageInput';
import AlarmToneSelect from './AlarmToneSelect';
import AlarmFrequencySelect from './AlarmFrequencySelect';

interface CreateAlarmFormProps {
  dreamId: string;
  onSubmit: (data: CreateAlarmData) => Promise<void>;
  onCancel: () => void;
}

export default function CreateAlarmForm({
  dreamId,
  onSubmit,
  onCancel
}: CreateAlarmFormProps) {
  const [formData, setFormData] = useState<Omit<CreateAlarmData, 'dream_id'>>({
    time: '08:00',
    message: '',
    tone: 'neutral',
    frequency: 'once'
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await onSubmit({
        dream_id: dreamId,
        ...formData
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create alarm');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-3 bg-red-50 text-red-700 rounded-md text-sm">
          {error}
        </div>
      )}

      <TimeInput
        value={formData.time}
        onChange={(time) => setFormData(prev => ({ ...prev, time }))}
      />

      <MessageInput
        value={formData.message}
        onChange={(message) => setFormData(prev => ({ ...prev, message }))}
      />

      <AlarmToneSelect
        value={formData.tone}
        onChange={(tone) => setFormData(prev => ({ ...prev, tone }))}
      />

      <AlarmFrequencySelect
        value={formData.frequency}
        onChange={(frequency) => setFormData(prev => ({ ...prev, frequency }))}
      />

      <div className="flex flex-col-reverse sm:flex-row gap-3 sm:justify-end pt-4">
        <button
          type="button"
          onClick={onCancel}
          className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 transition-colors"
        >
          <Bell className="w-4 h-4" />
          {loading ? 'Setting Alarm...' : 'Set Alarm'}
        </button>
      </div>
    </form>
  );
}