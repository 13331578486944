import React, { useState } from 'react';
import type { User } from '@supabase/supabase-js';

interface CommentFormProps {
  onSubmit: (content: string) => Promise<void>;
  user: User | null;
}

export default function CommentForm({ onSubmit, user }: CommentFormProps) {
  const [newComment, setNewComment] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!user || !newComment.trim()) return;

    setSubmitting(true);
    try {
      await onSubmit(newComment.trim());
      setNewComment('');
    } catch (err) {
      console.error('Error adding comment:', err);
    } finally {
      setSubmitting(false);
    }
  }

  if (!user) return null;

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-2">
        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
          Add a comment
        </label>
        <span className="text-xs text-gray-500">
          Supports Markdown formatting
        </span>
      </div>
      <textarea
        id="comment"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Share your thoughts..."
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm sm:text-base"
        rows={6}
        maxLength={100000}
      />
      <button
        type="submit"
        disabled={submitting || !newComment.trim()}
        className="w-full sm:w-auto px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 transition-colors text-sm sm:text-base"
      >
        {submitting ? 'Posting...' : 'Post Comment'}
      </button>
    </form>
  );
}