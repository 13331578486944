import { DatabaseError } from './DatabaseError';
import { NetworkError } from './NetworkError';
import { SupabaseError } from './SupabaseError';
import type { ErrorCode } from './types';

export function handleSupabaseError(error: unknown, code?: ErrorCode): never {
  // Log error for debugging
  console.error('Supabase operation failed:', {
    error,
    message: error instanceof Error ? error.message : 'Unknown error',
    stack: error instanceof Error ? error.stack : undefined
  });

  if (error instanceof SupabaseError) {
    throw error;
  }

  if (NetworkError.isNetworkError(error)) {
    throw new NetworkError({
      message: 'Connection lost. Please check your internet connection and try again.',
      code: 'NETWORK_ERROR',
      cause: error
    });
  }

  throw new SupabaseError({
    message: error instanceof Error ? error.message : 'An unexpected error occurred',
    code: code || 'UNKNOWN_ERROR',
    cause: error
  });
}

export function handleDatabaseError(error: unknown, code?: ErrorCode): never {
  // Log error for debugging
  console.error('Database operation failed:', {
    error,
    message: error instanceof Error ? error.message : 'Unknown error',
    stack: error instanceof Error ? error.stack : undefined
  });

  if (error instanceof DatabaseError) {
    throw error;
  }

  throw new DatabaseError({
    message: error instanceof Error ? error.message : 'An unexpected database error occurred',
    code: code || 'DATABASE_ERROR',
    cause: error
  });
}