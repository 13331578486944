import React from 'react';
import NeuralNetwork from './NeuralNetwork';
import DreamBubbles from './DreamBubbles';

interface AnimatedBackgroundProps {
  type?: 'neural' | 'bubbles' | 'both';
  opacity?: 'low' | 'medium' | 'high';
}

export default function AnimatedBackground({ 
  type = 'both',
  opacity = 'medium' 
}: AnimatedBackgroundProps) {
  const opacityClass = {
    low: 'opacity-5',
    medium: 'opacity-10',
    high: 'opacity-20'
  }[opacity];

  return (
    <div className={`fixed inset-0 overflow-hidden ${opacityClass} pointer-events-none`}>
      {(type === 'neural' || type === 'both') && <NeuralNetwork />}
      {(type === 'bubbles' || type === 'both') && <DreamBubbles />}
    </div>
  );
}