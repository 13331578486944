import { DreamAlarm } from '../../../types/alarms';
import { BrowserNotifier } from '../notifications/BrowserNotifier';
import { EmailNotifier } from '../notifications/EmailNotifier';

export class NotificationManager {
  private static instance: NotificationManager;
  private browserNotifier: BrowserNotifier;
  private emailNotifier: EmailNotifier;

  private constructor() {
    this.browserNotifier = new BrowserNotifier();
    this.emailNotifier = new EmailNotifier();
  }

  static getInstance(): NotificationManager {
    if (!NotificationManager.instance) {
      NotificationManager.instance = new NotificationManager();
    }
    return NotificationManager.instance;
  }

  async notify(alarm: DreamAlarm): Promise<void> {
    await Promise.allSettled([
      this.browserNotifier.notify(alarm),
      this.emailNotifier.notify(alarm)
    ]);
  }
}