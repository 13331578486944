import React from 'react';
import DreamCard from './DreamCard';
import type { Dream } from '../types/dreams';

interface DreamFeedProps {
  dreams: Dream[];
  onLike: (dreamId: string) => void;
  onComment: (dreamId: string) => void;
}

export default function DreamFeed({ dreams, onLike, onComment }: DreamFeedProps) {
  return (
    <div className="space-y-4 sm:space-y-6">
      {dreams.map((dream) => (
        <DreamCard
          key={dream.id}
          dream={dream}
          onLike={() => onLike(dream.id)}
          onComment={() => onComment(dream.id)}
        />
      ))}
    </div>
  );
}