import React from 'react';
import { Moon, Target } from 'lucide-react';
import type { DreamCategory } from '../../types/dreams';

interface DreamCategorySelectProps {
  value: DreamCategory;
  onChange: (category: DreamCategory) => void;
}

export default function DreamCategorySelect({ value, onChange }: DreamCategorySelectProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Dream Category
      </label>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <button
          type="button"
          onClick={() => onChange('sleep')}
          className={`flex items-center justify-center gap-2 p-3 rounded-lg border transition-colors ${
            value === 'sleep'
              ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
              : 'border-gray-300 hover:bg-gray-50'
          }`}
        >
          <Moon className="w-5 h-5 flex-shrink-0" />
          <span className="text-sm sm:text-base">Dream from Sleep</span>
        </button>
        
        <button
          type="button"
          onClick={() => onChange('aspirational')}
          className={`flex items-center justify-center gap-2 p-3 rounded-lg border transition-colors ${
            value === 'aspirational'
              ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
              : 'border-gray-300 hover:bg-gray-50'
          }`}
        >
          <Target className="w-5 h-5 flex-shrink-0" />
          <span className="text-sm sm:text-base">Aspirational Dream</span>
        </button>
      </div>
    </div>
  );
}