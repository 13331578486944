import React from 'react';
import { Brain, Moon, Target, Sparkles, Calendar, MessageCircle } from 'lucide-react';

const features = [
  {
    icon: <Moon className="h-6 w-6" />,
    title: 'Dream Interpretation',
    description: 'Decode messages from your subconscious mind through advanced AI analysis of your sleep dreams.'
  },
  {
    icon: <Target className="h-6 w-6" />,
    title: 'Aspiration Planning',
    description: 'Transform your life goals into actionable plans with AI-powered guidance and milestone tracking.'
  },
  {
    icon: <Calendar className="h-6 w-6" />,
    title: 'Productivity Integration',
    description: 'Seamlessly sync with Jira, Notion, and calendars to align your dreams with daily actions.'
  },
  {
    icon: <Brain className="h-6 w-6" />,
    title: 'AI Insights Dashboard',
    description: 'Get personalized insights on your productivity patterns and work-life harmony.'
  },
  {
    icon: <Sparkles className="h-6 w-6" />,
    title: 'Smart Task Management',
    description: 'Intelligently organize tasks across platforms with priority-based scheduling.'
  },
  {
    icon: <MessageCircle className="h-6 w-6" />,
    title: 'Community Support',
    description: 'Share experiences and insights with fellow dreamers on similar journeys.'
  }
];

export default function Features() {
  return (
    <div className="py-8 sm:py-12 bg-white/80 backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900">
            Your Complete Dream-to-Reality Platform
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-base sm:text-lg text-gray-500">
            Bridging your subconscious insights, life aspirations, and daily productivity
          </p>
        </div>

        <div className="mt-8 sm:mt-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {features.map((feature, index) => (
              <div key={index} className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-200"></div>
                <div className="relative bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <div className="flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 mb-4 rounded-md bg-indigo-500 text-white">
                    {feature.icon}
                  </div>
                  <h3 className="text-base sm:text-lg font-semibold text-gray-900">{feature.title}</h3>
                  <p className="mt-2 text-sm sm:text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}