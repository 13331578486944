import type { AlarmSound } from '../../types/alarms';

export const ALARM_SOUNDS: AlarmSound[] = [
  {
    id: 'calming',
    name: 'Calming',
    description: 'Gentle, soothing tones for peaceful dreams',
    url: '/sounds/calming.mp3'
  },
  {
    id: 'energizing',
    name: 'Energizing',
    description: 'Uplifting melody to start your day',
    url: '/sounds/energizing.mp3'
  },
  {
    id: 'neutral',
    name: 'Neutral',
    description: 'Standard alarm sound',
    url: '/sounds/neutral.mp3'
  }
];

export const ALARM_FREQUENCIES = [
  { id: 'once', label: 'One-time' },
  { id: 'daily', label: 'Daily' },
  { id: 'weekly', label: 'Weekly' }
] as const;