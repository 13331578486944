import React from 'react';
import { Book } from 'lucide-react';
import { useShareDream } from '../hooks/useShareDream';
import { useDreamForm } from '../hooks/forms/useDreamForm';
import { ErrorAlert } from './ui/ErrorAlert';
import DreamForm from './share/DreamForm';
import NeuralAnimation from './animations/NeuralAnimation';

export default function ShareDream() {
  const { handleSubmit: submitDream, loading, error, isAnalyzing, clearError } = useShareDream();
  const { formData, updateField, handleSubmit, resetForm } = useDreamForm({
    onSubmit: async (data) => {
      try {
        await submitDream(data);
        resetForm();
      } catch (error) {
        console.error('Error sharing dream:', error);
      }
    }
  });

  return (
    <div className="bg-white rounded-lg shadow-md p-6 relative overflow-hidden">
      <div className="relative z-10">
        <div className="flex items-center gap-2 mb-4">
          <Book className="w-5 h-5 text-indigo-600" />
          <h2 className="text-lg font-medium text-gray-900">Share Your Dream</h2>
        </div>

        {error && (
          <ErrorAlert message={error} onClose={clearError} />
        )}

        <DreamForm
          formData={formData}
          onUpdateField={updateField}
          onSubmit={handleSubmit}
          loading={loading}
          isAnalyzing={isAnalyzing}
        />
      </div>

      <div className="absolute inset-0 opacity-5 pointer-events-none">
        <NeuralAnimation />
      </div>
    </div>
  );
}