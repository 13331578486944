import { DreamAlarm } from '../../types/alarms';
import { supabase } from '../supabase';

class NotificationService {
  private static instance: NotificationService;
  private hasPermission: boolean = false;

  private constructor() {
    this.checkPermission();
  }

  static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  private async checkPermission(): Promise<void> {
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications');
      return;
    }

    if (Notification.permission === 'granted') {
      this.hasPermission = true;
    } else if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      this.hasPermission = permission === 'granted';
    }
  }

  async updateNotificationPreferences(preferences: {
    email?: boolean;
    email_address?: string;
  }): Promise<void> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    await supabase
      .from('profiles')
      .update({
        email_notifications: preferences.email,
        notification_email: preferences.email_address,
        notification_preferences: {
          browser: this.hasPermission,
          ...preferences
        }
      })
      .eq('id', user.id);
  }

  async showNotification(alarm: DreamAlarm): Promise<void> {
    if (!this.hasPermission) {
      await this.checkPermission();
    }

    if (!this.hasPermission) {
      console.warn('Notification permission not granted');
      return;
    }

    const title = alarm.dreams?.title 
      ? `Dream Alarm: ${alarm.dreams.title}`
      : 'Dream Alarm';

    const options: NotificationOptions = {
      body: alarm.message,
      icon: '/vite.svg',
      badge: '/vite.svg',
      tag: `dream-alarm-${alarm.id}`,
      requireInteraction: true,
      actions: [
        { action: 'snooze', title: 'Snooze 5m' },
        { action: 'dismiss', title: 'Dismiss' }
      ]
    };

    const notification = new Notification(title, options);

    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
    });

    notification.addEventListener('action', (event) => {
      if (event instanceof NotificationEvent) {
        if (event.action === 'snooze') {
          setTimeout(() => this.showNotification(alarm), 5 * 60 * 1000);
        }
        notification.close();
      }
    });
  }
}

export const notificationService = NotificationService.getInstance();