import React from 'react';
import { CheckCircle2, AlertCircle } from 'lucide-react';

interface ServiceCardProps {
  name: string;
  description: string;
  isConnected: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  icon: string;
}

export default function ServiceCard({
  name,
  description,
  isConnected,
  onConnect,
  onDisconnect,
  icon
}: ServiceCardProps) {
  return (
    <div className="relative group">
      <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-200"></div>
      <div className="relative bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-start gap-4">
          <img src={icon} alt={name} className="w-8 h-8 object-contain" />
          
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-medium text-gray-900">{name}</h3>
              {isConnected ? (
                <CheckCircle2 className="w-4 h-4 text-green-500" />
              ) : (
                <AlertCircle className="w-4 h-4 text-gray-400" />
              )}
            </div>
            <p className="mt-1 text-xs text-gray-500">{description}</p>
          </div>
        </div>

        <div className="mt-4">
          {isConnected ? (
            <button
              onClick={onDisconnect}
              className="w-full px-3 py-1.5 text-xs font-medium text-red-600 border border-red-200 rounded-md hover:bg-red-50 transition-colors"
            >
              Disconnect
            </button>
          ) : (
            <button
              onClick={onConnect}
              className="w-full px-3 py-1.5 text-xs font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors"
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
}