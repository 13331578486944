import React from 'react';
import { Lightbulb } from 'lucide-react';

interface RecommendationCardProps {
  title: string;
  description: string;
  actionSteps: string[];
}

export default function RecommendationCard({ title, description, actionSteps }: RecommendationCardProps) {
  return (
    <div className="border-l-2 border-indigo-200 pl-4">
      <div className="flex items-center gap-2 mb-2">
        <Lightbulb className="w-4 h-4 text-indigo-600 flex-shrink-0" />
        <h4 className="font-medium text-gray-900 text-sm sm:text-base">{title}</h4>
      </div>
      <p className="text-sm sm:text-base text-gray-600 mb-3 leading-relaxed">{description}</p>
      <ul className="list-disc list-inside space-y-1">
        {actionSteps.map((step, index) => (
          <li key={index} className="text-sm text-gray-500 leading-relaxed">{step}</li>
        ))}
      </ul>
    </div>
  );
}