import React from 'react';
import { X } from 'lucide-react';
import type { CreateAlarmData } from '../../types/alarms';
import CreateAlarmForm from './CreateAlarmForm';

interface AlarmModalProps {
  dreamId: string;
  onClose: () => void;
  onSubmit: (data: CreateAlarmData) => Promise<void>;
}

export default function AlarmModal({ dreamId, onClose, onSubmit }: AlarmModalProps) {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg w-full max-w-md mx-auto my-8">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-4 sm:p-6 border-b">
          <h2 className="text-lg sm:text-xl font-bold text-gray-900">Set Dream Alarm</h2>
          <button 
            onClick={onClose} 
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close modal"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        {/* Modal Content */}
        <div className="p-4 sm:p-6">
          <CreateAlarmForm
            dreamId={dreamId}
            onSubmit={async (data) => {
              await onSubmit(data);
              onClose();
            }}
            onCancel={onClose}
          />
        </div>
      </div>
    </div>
  );
}