import { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { createDream } from '../lib/api/dreams';
import { useDreamFeed } from './useDreamFeed';
import type { CreateDreamData } from '../types/dreams';

export function useShareDream() {
  const { user } = useAuth();
  const { refreshDreams } = useDreamFeed(user?.id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleSubmit = useCallback(async (data: CreateDreamData) => {
    if (!user) {
      setError('You must be logged in to share dreams');
      return;
    }

    if (!data?.content?.trim()) {
      setError('Dream content is required');
      return;
    }

    setLoading(true);
    setError(null);
    setIsAnalyzing(true);

    try {
      await createDream({
        content: data.content,
        category: data.category || 'sleep',
        is_recurring: data.is_recurring || false,
        is_private: data.is_private || false
      });
      
      // Refresh dreams list after successful creation
      await refreshDreams();
    } catch (err) {
      console.error('Share dream error:', { error: err });
      const message = err instanceof Error ? err.message : 'Failed to share dream';
      setError(message);
      throw err;
    } finally {
      setLoading(false);
      setIsAnalyzing(false);
    }
  }, [user, refreshDreams]);

  const clearError = useCallback(() => setError(null), []);

  return {
    handleSubmit,
    loading,
    error,
    isAnalyzing,
    clearError
  };
}