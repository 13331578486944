import React from 'react';

export default function DreamBubbles() {
  const bubbles = [
    { size: 20, left: '10%', top: '5%', color: 'bg-indigo-300' },
    { size: 30, left: '25%', top: '15%', color: 'bg-indigo-400' },
    { size: 40, left: '40%', top: '25%', color: 'bg-indigo-500' },
    { size: 50, left: '55%', top: '35%', color: 'bg-indigo-400' },
    { size: 60, left: '70%', top: '45%', color: 'bg-indigo-300' },
    { size: 70, left: '85%', top: '55%', color: 'bg-indigo-200' },
  ];

  return (
    <div className="absolute inset-0 pointer-events-none">
      <div className="absolute w-full h-full">
        {bubbles.map((bubble, i) => (
          <div
            key={i}
            className={`absolute rounded-full ${bubble.color} opacity-10 blur-sm animate-float-${i + 1}`}
            style={{
              width: `${bubble.size}px`,
              height: `${bubble.size}px`,
              left: bubble.left,
              top: bubble.top,
              animationDelay: `${i * 0.5}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}