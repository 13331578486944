import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { outlookAuthService } from '../../lib/services/auth/OutlookAuthService';
import LoadingState from '../../components/ui/LoadingState';
import ErrorMessage from '../../components/ui/ErrorMessage';

export default function OutlookCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      const error = searchParams.get('error');

      if (error) {
        setError('Authentication failed: ' + error);
        return;
      }

      if (!code || !state) {
        setError('Invalid callback parameters');
        return;
      }

      try {
        await outlookAuthService.handleCallback(code, state);
        navigate('/productivity', { 
          state: { message: 'Successfully connected to Outlook Calendar' }
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to complete authentication');
      }
    };

    handleCallback();
  }, [searchParams, navigate]);

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return <LoadingState message="Completing Outlook Calendar integration..." />;
}