import { supabase } from '../../supabase';

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const GOOGLE_REDIRECT_URI = `${window.location.origin}/auth/google/callback`;
const GOOGLE_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events'
].join(' ');

export class GoogleAuthService {
  private static instance: GoogleAuthService;

  private constructor() {}

  static getInstance(): GoogleAuthService {
    if (!GoogleAuthService.instance) {
      GoogleAuthService.instance = new GoogleAuthService();
    }
    return GoogleAuthService.instance;
  }

  async initiateAuth() {
    if (!GOOGLE_CLIENT_ID) {
      throw new Error('Google Calendar integration is not configured. Please contact support.');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Authentication required');

      const state = crypto.randomUUID();
      localStorage.setItem('googleOAuthState', state);

      const params = new URLSearchParams({
        client_id: GOOGLE_CLIENT_ID,
        redirect_uri: GOOGLE_REDIRECT_URI,
        response_type: 'code',
        scope: GOOGLE_SCOPES,
        access_type: 'offline',
        state,
        prompt: 'consent'
      });

      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    } catch (error) {
      console.error('Google OAuth initiation failed:', error);
      throw new Error('Failed to start Google Calendar integration. Please try again.');
    }
  }

  async handleCallback(code: string, state: string): Promise<void> {
    const storedState = localStorage.getItem('googleOAuthState');
    localStorage.removeItem('googleOAuthState');

    if (!storedState || state !== storedState) {
      throw new Error('Invalid OAuth state');
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Authentication required');

      const { error } = await supabase
        .from('user_integrations')
        .upsert({
          user_id: user.id,
          service: 'google_calendar',
          auth_code: code,
          connected_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Failed to handle Google OAuth callback:', error);
      throw new Error('Failed to complete Google Calendar integration');
    }
  }
}

export const googleAuthService = GoogleAuthService.getInstance();