import { supabase } from '../supabase';
import type { Profile } from '../../types/profile';
import type { DreamStatistics } from '../../types/profile';

export async function getProfileStats(userId: string): Promise<DreamStatistics> {
  // Get dreams count
  const { data: dreams, error: dreamsError } = await supabase
    .from('dreams')
    .select('id, is_private')
    .eq('user_id', userId);

  if (dreamsError) throw dreamsError;

  // Get comments count
  const { data: comments, error: commentsError } = await supabase
    .from('dream_comments')
    .select(`
      id,
      dreams!inner (user_id)
    `);

  if (commentsError) throw commentsError;

  // Get reactions count
  const { data: reactions, error: reactionsError } = await supabase
    .from('dream_reactions')
    .select(`
      id,
      dreams!inner (user_id)
    `);

  if (reactionsError) throw reactionsError;

  const publicDreams = dreams?.filter(d => !d.is_private).length || 0;
  const privateDreams = dreams?.filter(d => d.is_private).length || 0;

  const receivedComments = comments?.filter(c => c.dreams.user_id === userId).length || 0;
  const givenComments = comments?.filter(c => c.dreams.user_id !== userId).length || 0;

  const receivedReactions = reactions?.filter(r => r.dreams.user_id === userId).length || 0;
  const givenReactions = reactions?.filter(r => r.dreams.user_id !== userId).length || 0;

  return {
    totalDreams: dreams?.length || 0,
    publicDreams,
    privateDreams,
    totalComments: receivedComments + givenComments,
    receivedComments,
    givenComments,
    totalReactions: receivedReactions + givenReactions,
    receivedReactions,
    givenReactions
  };
}