import React, { useState } from 'react';
import { Bell, Plus } from 'lucide-react';
import CreateAlarmModal from '../modals/CreateAlarmModal';
import { useAlarms } from '../../../hooks/useAlarms';

interface AlarmHeaderProps {
  statistics: {
    totalAlarms: number;
    activeAlarms: number;
    themes: string[];
  };
}

export default function AlarmHeader({ statistics }: AlarmHeaderProps) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { addAlarm } = useAlarms();

  return (
    <>
      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
          <div className="w-full sm:w-auto">
            <div className="flex items-center gap-3 mb-2">
              <Bell className="w-6 h-6 sm:w-8 sm:h-8 text-indigo-600" />
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Your Dream Alarms</h1>
            </div>
            <p className="text-sm sm:text-base text-gray-600">
              Aligning your dreams with daily actions
            </p>
            {statistics.themes.length > 0 && (
              <p className="mt-2 text-sm text-indigo-600">
                Top themes this week: {statistics.themes.join(', ')}
              </p>
            )}
          </div>

          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
            <div className="text-left sm:text-right w-full sm:w-auto">
              <p className="text-sm text-gray-600">Active Alarms</p>
              <p className="text-xl sm:text-2xl font-bold text-indigo-600">
                {statistics.activeAlarms}
                <span className="text-sm text-gray-500 ml-1">/ {statistics.totalAlarms}</span>
              </p>
            </div>
            
            <button 
              onClick={() => setShowCreateModal(true)}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Plus className="w-5 h-5" />
              <span>New Alarm</span>
            </button>
          </div>
        </div>
      </div>

      {showCreateModal && (
        <CreateAlarmModal
          onClose={() => setShowCreateModal(false)}
          onSubmit={addAlarm}
        />
      )}
    </>
  );
}