import type { AspirationPlan } from '../../../types/dreams';

export function formatAspirationPlan(plan: any): string {
  try {
    const data = typeof plan === 'string' ? JSON.parse(plan) : plan;

    // Format steps section with validation
    const stepsSection = data.steps?.length 
      ? data.steps
          .map((step: any, index: number) => {
            if (!step?.title || !step?.description) return null;
            return `### Step ${index + 1}: ${step.title.trim()}
${step.description.trim()}${step.timeframe ? `\n⏱️ Timeline: ${step.timeframe.trim()}` : ''}`;
          })
          .filter(Boolean)
          .join('\n\n')
      : 'No action steps defined';

    // Format milestones section with validation
    const milestonesSection = data.milestones?.length
      ? data.milestones
          .map((milestone: any) => {
            if (!milestone?.title || !milestone?.criteria) return null;
            return `- **${milestone.title.trim()}**\n  ${milestone.criteria.trim()}`;
          })
          .filter(Boolean)
          .join('\n')
      : 'No milestones defined';

    return `# ${data.title?.trim() || 'Aspiration Plan'} 🎯

## Overview
${data.overview?.trim() || 'No overview available'}

## Action Steps
${stepsSection}

## Key Milestones
${milestonesSection}

---
*React to this plan:*
- 💡 **Insightful**
- 🎯 **Actionable**
- ✨ **Inspiring**
- 💪 **Motivating**`;
  } catch (error) {
    console.error('Error formatting aspiration plan:', error);
    return `# Aspiration Plan 🎯

An error occurred while formatting the plan. Please try again later.

---
*React to this plan:*
- 💡 **Insightful**
- 🎯 **Actionable**
- ✨ **Inspiring**
- 💪 **Motivating**`;
  }
}