import React from 'react';
import { Lock, Globe } from 'lucide-react';

interface PrivacyToggleProps {
  value: boolean;
  onChange: (isPrivate: boolean) => void;
}

export default function PrivacyToggle({ value, onChange }: PrivacyToggleProps) {
  return (
    <button
      type="button"
      onClick={() => onChange(!value)}
      className={`w-full sm:w-auto flex items-center justify-center gap-1 px-4 py-2 rounded-full text-sm transition-colors ${
        value
          ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          : 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
      }`}
    >
      {value ? (
        <>
          <Lock className="w-4 h-4" />
          <span>Private</span>
        </>
      ) : (
        <>
          <Globe className="w-4 h-4" />
          <span>Public</span>
        </>
      )}
    </button>
  );
}