import React, { useState } from 'react';
import { Volume2, VolumeX } from 'lucide-react';
import { ALARM_SOUNDS } from '../../lib/alarms/constants';
import { playAlarmSound } from '../../lib/alarms/sound';
import type { DreamAlarm } from '../../types/alarms';

interface AlarmToneSelectProps {
  value: DreamAlarm['tone'];
  onChange: (tone: DreamAlarm['tone']) => void;
}

export default function AlarmToneSelect({ value, onChange }: AlarmToneSelectProps) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePreview = async (e: React.MouseEvent, tone: DreamAlarm['tone']) => {
    e.stopPropagation();
    if (isPlaying) return;
    
    setIsPlaying(true);
    try {
      await playAlarmSound(tone);
    } catch (error) {
      console.warn('Failed to play preview:', error);
    } finally {
      setIsPlaying(false);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Alarm Tone
      </label>
      <div className="mt-2 space-y-2">
        {ALARM_SOUNDS.map(sound => (
          <div
            key={sound.id}
            onClick={() => onChange(sound.id)}
            className={`w-full flex items-center justify-between px-4 py-3 border rounded-lg text-sm cursor-pointer
              ${value === sound.id
                ? 'border-indigo-500 text-indigo-700 bg-indigo-50'
                : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
              }`}
          >
            <div>
              <p className="font-medium">{sound.name}</p>
              <p className="text-xs text-gray-500">{sound.description}</p>
            </div>
            <button
              type="button"
              onClick={(e) => handlePreview(e, sound.id)}
              className={`p-2 rounded-full transition-colors ${
                value === sound.id ? 'text-indigo-500' : 'text-gray-400'
              } hover:bg-gray-100`}
              disabled={isPlaying}
            >
              {isPlaying ? (
                <VolumeX className="w-5 h-5" />
              ) : (
                <Volume2 className="w-5 h-5" />
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}