import React from 'react';
import { User, Settings } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import type { Profile } from '../../types/profile';

interface ProfileHeaderProps {
  profile: Profile;
  onEditProfile: () => void;
}

export default function ProfileHeader({ profile, onEditProfile }: ProfileHeaderProps) {
  const { user } = useAuth();

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4 sm:gap-0">
        <div className="flex items-center gap-4">
          <div className="bg-indigo-100 p-2 sm:p-3 rounded-full flex-shrink-0">
            <User className="w-6 h-6 sm:w-8 sm:h-8 text-indigo-600" />
          </div>
          <div>
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 break-words">
              {profile.public_name || profile.username}
            </h1>
            <p className="text-sm sm:text-base text-gray-500 mt-1 break-words">
              {profile.bio || 'No bio yet'}
            </p>
          </div>
        </div>
        <button
          onClick={onEditProfile}
          className="flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-gray-600 hover:text-gray-900 rounded-md hover:bg-gray-50 transition-colors"
        >
          <Settings className="w-4 h-4" />
          Edit Profile
        </button>
      </div>
    </div>
  );
}