import React from 'react';
import { BarChart } from 'lucide-react';

export default function TasksOverview() {
  // Sample data - in real app, this would come from API
  const taskData = {
    jira: { high: 3, medium: 5, low: 2 },
    notion: { high: 2, medium: 4, low: 3 }
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-indigo-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-indigo-900 mb-2">Jira Tasks</h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-xs text-indigo-700">High Priority</span>
              <span className="text-sm font-medium text-indigo-900">{taskData.jira.high}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs text-indigo-700">Medium Priority</span>
              <span className="text-sm font-medium text-indigo-900">{taskData.jira.medium}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs text-indigo-700">Low Priority</span>
              <span className="text-sm font-medium text-indigo-900">{taskData.jira.low}</span>
            </div>
          </div>
        </div>

        <div className="bg-purple-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-purple-900 mb-2">Notion Tasks</h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-xs text-purple-700">High Priority</span>
              <span className="text-sm font-medium text-purple-900">{taskData.notion.high}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs text-purple-700">Medium Priority</span>
              <span className="text-sm font-medium text-purple-900">{taskData.notion.medium}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs text-purple-700">Low Priority</span>
              <span className="text-sm font-medium text-purple-900">{taskData.notion.low}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="h-48 flex items-end justify-between px-2">
        {/* Simplified bar chart visualization */}
        <div className="flex items-end gap-2">
          <div className="w-8 bg-indigo-200 rounded-t" style={{ height: '60%' }}></div>
          <div className="w-8 bg-indigo-300 rounded-t" style={{ height: '80%' }}></div>
          <div className="w-8 bg-indigo-400 rounded-t" style={{ height: '40%' }}></div>
        </div>
        <div className="flex items-end gap-2">
          <div className="w-8 bg-purple-200 rounded-t" style={{ height: '50%' }}></div>
          <div className="w-8 bg-purple-300 rounded-t" style={{ height: '70%' }}></div>
          <div className="w-8 bg-purple-400 rounded-t" style={{ height: '30%' }}></div>
        </div>
      </div>
    </div>
  );
}