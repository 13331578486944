export class AIServiceError extends Error {
  code: string;
  retryable: boolean;

  constructor({ code, message, retryable }: {
    code: string;
    message: string;
    retryable: boolean;
  }) {
    super(message);
    this.name = 'AIServiceError';
    this.code = code;
    this.retryable = retryable;
  }
}