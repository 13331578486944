import { callOpenAI } from './openai';
import type { Dream, CreateDreamData } from '../../../types/dreams';

const DREAM_ANALYSIS_PROMPT = `Analyze this dream and provide:
1. A meaningful title (max 60 chars)
2. A detailed interpretation
3. Analysis of key symbols
Format as JSON with title, interpretation, and symbols_analysis keys.`;

const ASPIRATION_PLAN_PROMPT = `Create an actionable plan for this aspiration. Return a JSON object with:
{
  "title": "Brief, meaningful title (max 60 chars)",
  "overview": "Clear overview of the plan",
  "steps": [
    {
      "title": "Step title",
      "description": "Detailed step description",
      "timeframe": "Estimated timeline"
    }
  ],
  "milestones": [
    {
      "title": "Milestone title",
      "criteria": "Success criteria"
    }
  ]
}`;

export async function processDream(dream: Dream | (CreateDreamData & { id: string; user_id: string; title: string; created_at: string })) {
  const prompt = dream.category === 'sleep' 
    ? DREAM_ANALYSIS_PROMPT 
    : ASPIRATION_PLAN_PROMPT;

  const response = await callOpenAI(`${prompt}\n\nContent: ${dream.content}`);
  
  try {
    const parsed = JSON.parse(response);
    
    // Validate and structure the response
    if (dream.category === 'sleep') {
      return {
        title: parsed.title || 'Dream Analysis',
        interpretation: parsed.interpretation || 'No interpretation available',
        symbols_analysis: parsed.symbols_analysis || {}
      };
    } else {
      return {
        title: parsed.title || 'Aspiration Plan',
        overview: parsed.overview || 'No overview available',
        steps: Array.isArray(parsed.steps) ? parsed.steps : [],
        milestones: Array.isArray(parsed.milestones) ? parsed.milestones : []
      };
    }
  } catch (error) {
    console.error('Failed to parse OpenAI response:', error);
    throw new Error('Invalid response format from AI service');
  }
}