import { supabase } from '../supabase';
import { processDream } from '../ai/service/dreamProcessor';
import { formatDreamInterpretation } from '../ai/formatters/dreamFormatter';
import { formatAspirationPlan } from '../ai/formatters/aspirationFormatter';
import { withRetry } from '../utils/retry';
import type { CreateDreamData, Dream } from '../../types/dreams';

export async function getDream(dreamId: string): Promise<Dream> {
  return withRetry(async () => {
    const { data, error } = await supabase
      .from('dreams')
      .select(`
        *,
        dream_interpretations (
          interpretation,
          symbols_analysis,
          is_plan
        )
      `)
      .eq('id', dreamId)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Dream not found');
    
    return data;
  });
}

export async function createDream(data: CreateDreamData): Promise<Dream> {
  if (!data.content?.trim()) {
    throw new Error('Dream content is required');
  }

  return withRetry(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Must be logged in to create dreams');

      // Process with OpenAI first to get title
      const aiResponse = await processDream({
        ...data,
        id: '',
        user_id: user.id,
        title: '',
        created_at: new Date().toISOString()
      });

      // Create dream record with AI-generated title
      const { data: dream, error: dreamError } = await supabase
        .from('dreams')
        .insert({
          user_id: user.id,
          title: aiResponse.title || (data.category === 'sleep' ? 'Dream Analysis' : 'Aspiration Plan'),
          content: data.content.trim(),
          category: data.category,
          is_private: data.is_private,
          is_recurring: data.is_recurring
        })
        .select()
        .single();

      if (dreamError) throw dreamError;
      if (!dream) throw new Error('Failed to create dream');

      // Save interpretation
      const { error: interpretationError } = await supabase
        .from('dream_interpretations')
        .insert({
          dream_id: dream.id,
          interpretation: JSON.stringify(aiResponse),
          is_plan: data.category === 'aspirational'
        });

      if (interpretationError) throw interpretationError;

      // Format and create AI comment
      const formattedContent = data.category === 'sleep'
        ? formatDreamInterpretation(aiResponse)
        : formatAspirationPlan(aiResponse);

      const { error: commentError } = await supabase
        .from('dream_comments')
        .insert({
          dream_id: dream.id,
          content: formattedContent,
          is_ai: true,
          ai_model: data.category === 'sleep' ? 'dream-analysis' : 'aspiration-planning'
        });

      if (commentError) throw commentError;

      return dream;
    } catch (error) {
      console.error('Failed to create dream:', error);
      throw error;
    }
  });
}