import React from 'react';
import { format } from 'date-fns';
import type { Comment } from '../../lib/comments/types';
import MarkdownRenderer from '../markdown/MarkdownRenderer';

interface CommentDisplayProps {
  comment: Comment;
}

export default function CommentDisplay({ comment }: CommentDisplayProps) {
  const displayName = comment.profiles?.display_name || 
                     comment.profiles?.public_name || 
                     comment.profiles?.username || 
                     'Anonymous';

  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-2 mb-3">
        <span className="font-medium text-gray-900 text-sm sm:text-base">{displayName}</span>
        <span className="text-xs sm:text-sm text-gray-500 whitespace-nowrap">
          {format(new Date(comment.created_at), 'PPp')}
        </span>
      </div>
      <div className="text-sm sm:text-base text-gray-700 prose-sm sm:prose-base">
        <MarkdownRenderer content={comment.content} />
      </div>
    </div>
  );
}