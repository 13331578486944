import React from 'react';
import { Bell, BellOff, ListFilter } from 'lucide-react';
import type { DreamAlarm } from '../../../types/alarms';
import AlarmList from '../AlarmList';

interface AlarmTabsProps {
  alarms: DreamAlarm[];
  activeTab: 'active' | 'inactive' | 'all';
  onTabChange: (tab: 'active' | 'inactive' | 'all') => void;
  onToggle: (id: string, active: boolean) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  statistics: {
    totalAlarms: number;
    activeAlarms: number;
  };
}

export default function AlarmTabs({
  alarms,
  activeTab,
  onTabChange,
  onToggle,
  onDelete,
  statistics
}: AlarmTabsProps) {
  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="border-b border-gray-200">
        <div className="flex flex-wrap gap-2 p-2 sm:p-0">
          <button
            onClick={() => onTabChange('active')}
            className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
              activeTab === 'active'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Bell className="w-4 h-4" />
            <span>Active</span>
            <span className="ml-2 bg-indigo-100 text-indigo-600 px-2 py-0.5 rounded-full">
              {statistics.activeAlarms}
            </span>
          </button>

          <button
            onClick={() => onTabChange('inactive')}
            className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
              activeTab === 'inactive'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <BellOff className="w-4 h-4" />
            <span>Inactive</span>
            <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full">
              {statistics.totalAlarms - statistics.activeAlarms}
            </span>
          </button>

          <button
            onClick={() => onTabChange('all')}
            className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
              activeTab === 'all'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <ListFilter className="w-4 h-4" />
            <span>All</span>
            <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full">
              {statistics.totalAlarms}
            </span>
          </button>
        </div>
      </div>

      <div className="p-4 sm:p-6">
        <AlarmList
          alarms={alarms}
          onToggle={onToggle}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
}