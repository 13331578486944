import React from 'react';
import { ExternalLink } from 'lucide-react';

export default function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms of Service</h1>
        
        <div className="prose prose-indigo max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">1. Acceptance of Terms</h2>
            <p>By accessing or using DreamAlarm, you agree to be bound by these Terms of Service.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">2. Description of Service</h2>
            <p>DreamAlarm provides dream journaling and analysis services, including:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Dream recording and storage</li>
              <li>AI-powered dream analysis</li>
              <li>Community interaction features</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">3. User Accounts</h2>
            <p>You are responsible for:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Maintaining account security</li>
              <li>All activities under your account</li>
              <li>Providing accurate information</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">4. Content Guidelines</h2>
            <p>Users must not post content that is:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Illegal or promotes illegal activities</li>
              <li>Harassing or discriminatory</li>
              <li>Violating intellectual property rights</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">5. Third-Party Services</h2>
            <p>We use third-party services including:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Google Authentication{' '}
                <a 
                  href="https://policies.google.com/terms" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-800 inline-flex items-center"
                >
                  Terms <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">6. Contact Information</h2>
            <p>For questions about these Terms, contact us at:</p>
            <p className="text-indigo-600">hariom.devops@gmail.com</p>
          </section>
        </div>
      </div>
    </div>
  );
}