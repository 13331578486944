import { AIServiceError } from './errors';
import { AI_CONFIG } from './config';
import { withRetry } from './retry';
import { OPENAI_API_KEY } from '../config';

export async function callOpenAI(prompt: string) {
  if (!OPENAI_API_KEY) {
    throw new AIServiceError({
      code: 'CONFIG_ERROR',
      message: 'OpenAI API key is not configured',
      retryable: false
    });
  }

  return withRetry(async () => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: AI_CONFIG.model,
          messages: [{ role: 'user', content: prompt }],
          temperature: AI_CONFIG.temperature,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        
        throw new AIServiceError({
          code: response.status === 429 ? 'RATE_LIMIT' : 'API_ERROR',
          message: errorData.error?.message || `API error: ${response.statusText}`,
          retryable: response.status === 429 || response.status >= 500
        });
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      if (error instanceof AIServiceError) throw error;
      
      throw new AIServiceError({
        code: 'API_ERROR',
        message: 'Failed to process AI request',
        retryable: true
      });
    }
  }, {
    maxRetries: AI_CONFIG.maxRetries,
    baseDelay: AI_CONFIG.baseDelay
  });
}