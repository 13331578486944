import React from 'react';
import { ExternalLink } from 'lucide-react';

export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
        
        <div className="prose prose-indigo max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">Information We Collect</h2>
            <p>We collect information that you provide directly to us, including:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Account information (email, name)</li>
              <li>Dream journal entries and related content</li>
              <li>Usage data and interactions</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">How We Use Your Information</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>Provide and improve our services</li>
              <li>Personalize your experience</li>
              <li>Communicate with you about updates</li>
              <li>Ensure security and prevent fraud</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">Data Security</h2>
            <p>We implement appropriate security measures to protect your personal information.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">Third-Party Services</h2>
            <p>We use the following third-party services:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Google Authentication{' '}
                <a 
                  href="https://policies.google.com/privacy" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-800 inline-flex items-center"
                >
                  Privacy Policy <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              </li>
              <li>
                Supabase{' '}
                <a 
                  href="https://supabase.com/privacy" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-800 inline-flex items-center"
                >
                  Privacy Policy <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">Contact Us</h2>
            <p>If you have questions about this Privacy Policy, please contact us at:</p>
            <p className="text-indigo-600">hariom.devops@gmail.com</p>
          </section>
        </div>
      </div>
    </div>
  );
}