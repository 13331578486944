import React from 'react';
import { MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useComments } from '../hooks/useComments';
import type { Dream } from '../types/dreams';
import CommentList from './comments/CommentList';
import CommentForm from './comments/CommentForm';
import LoadingState from './ui/LoadingState';
import ErrorMessage from './ui/ErrorMessage';

interface DreamCommentsProps {
  dreamId: string;
  dream: Dream;
}

export default function DreamComments({ dreamId, dream }: DreamCommentsProps) {
  const { user } = useAuth();
  const { comments, loading, error, addComment } = useComments(dreamId);

  if (loading) return <LoadingState message="Loading comments..." />;
  if (error) return <ErrorMessage message="Failed to load comments. Please try again later." />;

  return (
    <div className="mt-4 border-t pt-4">
      <h3 className="flex items-center gap-2 text-lg font-medium text-gray-900 mb-4">
        <MessageCircle className="w-5 h-5" />
        Comments
      </h3>

      <CommentList comments={comments} />
      {user && <CommentForm user={user} onSubmit={addComment} />}
    </div>
  );
}