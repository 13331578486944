import type { CreateDreamData } from '../../types/dreams';

export function validateDreamForm(data: CreateDreamData): string | null {
  if (!data.content.trim()) {
    return 'Dream content is required';
  }
  
  if (!['sleep', 'aspirational'].includes(data.category)) {
    return 'Invalid dream category';
  }

  return null;
}