import React, { useState } from 'react';
import MarkdownRenderer from '../markdown/MarkdownRenderer';

interface ExpandableContentProps {
  content: string;
  maxLength?: number;
}

export default function ExpandableContent({ content, maxLength = 1000 }: ExpandableContentProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = content.length > maxLength;
  const displayContent = !isExpanded && shouldTruncate 
    ? `${content.slice(0, maxLength)}...` 
    : content;

  return (
    <div>
      <div className="prose prose-indigo max-w-none">
        <MarkdownRenderer content={displayContent} />
      </div>
      {shouldTruncate && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2 text-indigo-600 hover:text-indigo-700 text-sm font-medium"
        >
          {isExpanded ? 'Show Less' : 'Read More'}
        </button>
      )}
    </div>
  );
}