import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import HowItWorks from '../components/home/HowItWorks';
import DreamFeed from '../components/DreamFeed';
import LoadingState from '../components/LoadingState';
import ShareDream from '../components/ShareDream';
import BrainAnimation from '../components/home/BrainAnimation';
import { useDreamFeed } from '../hooks/useDreamFeed';

export default function Home() {
  const { user } = useAuth();
  const { dreams, loading } = useDreamFeed(user?.id);

  if (!user) {
    return (
      <div className="bg-gradient-to-b from-indigo-50 to-white min-h-screen">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 z-0">
            <BrainAnimation />
          </div>
          <div className="relative z-10 px-4">
            <Hero />
            <Features />
            <HowItWorks />
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <LoadingState />;
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <ShareDream />
      
      {dreams.length > 0 ? (
        <div className="mt-6 sm:mt-8 bg-white rounded-lg shadow-sm p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4 sm:mb-6">Your Journey</h2>
          <DreamFeed dreams={dreams} />
        </div>
      ) : (
        <div className="mt-6 sm:mt-8 text-center text-gray-600">
          <p>Share your first dream or aspiration above!</p>
        </div>
      )}
    </div>
  );
}