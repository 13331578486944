import type { DreamInterpretation } from '../../../types/dreams';

export function formatDreamInterpretation(interpretation: any): string {
  try {
    const data = typeof interpretation === 'string' ? JSON.parse(interpretation) : interpretation;

    let symbolsSection = '';
    if (data.symbols_analysis && Object.keys(data.symbols_analysis).length > 0) {
      symbolsSection = `\n## Symbol Analysis\n${Object.entries(data.symbols_analysis)
        .map(([symbol, meaning]) => `- **${symbol}**: ${meaning}`)
        .join('\n')}`;
    }

    return `# Dream Analysis 🌙

## Interpretation
${data.interpretation || 'No interpretation available'}
${symbolsSection}

---
*Share your thoughts in the comments below!*`;
  } catch (error) {
    console.error('Error formatting dream interpretation:', error);
    return 'Unable to format dream analysis. Please try again later.';
  }
}