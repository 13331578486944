import React from 'react';
import { MessageCircle, Lock, Moon, Target, Repeat } from 'lucide-react';
import { format } from 'date-fns';
import type { Dream } from '../types/dreams';
import DreamComments from './DreamComments';

interface DreamCardProps {
  dream: Dream;
}

export default function DreamCard({ dream }: DreamCardProps) {
  const CategoryIcon = dream.category === 'sleep' ? Moon : Target;

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-3 mb-4">
        <div className="flex-1 min-w-0">
          <h3 className="text-base sm:text-lg font-medium text-gray-900 break-words">{dream.title}</h3>
          <div className="flex flex-wrap items-center gap-2 mt-2 text-xs sm:text-sm text-gray-500">
            <span className="whitespace-nowrap">{format(new Date(dream.created_at), 'PPP')}</span>
            <span className="hidden sm:inline">•</span>
            <span className="flex items-center gap-1">
              <CategoryIcon className="w-4 h-4" />
              <span className="capitalize">
                {dream.category === 'sleep' ? 'Dream' : 'Aspiration'}
              </span>
            </span>
            {dream.category === 'sleep' && dream.is_recurring && (
              <>
                <span className="hidden sm:inline">•</span>
                <span className="flex items-center gap-1">
                  <Repeat className="w-4 h-4" />
                  Recurring
                </span>
              </>
            )}
          </div>
        </div>
        {dream.is_private && (
          <Lock className="w-4 h-4 text-gray-400 flex-shrink-0" />
        )}
      </div>

      <p className="text-sm sm:text-base text-gray-700 mb-4 leading-relaxed">{dream.content}</p>

      <DreamComments dreamId={dream.id} dream={dream} />
    </div>
  );
}