import React from 'react';
import { Brain, Sparkles, Compass } from 'lucide-react';

export default function LoadingState() {
  return (
    <div className="max-w-2xl mx-auto text-center py-12">
      <div className="flex justify-center space-x-4 mb-8 animate-pulse">
        <Brain className="w-8 h-8 text-indigo-500" />
        <Sparkles className="w-8 h-8 text-purple-500" />
        <Compass className="w-8 h-8 text-blue-500" />
      </div>
      
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
        Unlocking Your Dream Insights
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
        <div className="p-4">
          <Brain className="w-6 h-6 text-indigo-500 mx-auto mb-3" />
          <h3 className="font-medium text-gray-900 mb-2">Dream Analysis</h3>
          <p className="text-sm text-gray-600">AI-powered interpretation of your dream symbols and patterns</p>
        </div>
        
        <div className="p-4">
          <Sparkles className="w-6 h-6 text-purple-500 mx-auto mb-3" />
          <h3 className="font-medium text-gray-900 mb-2">Personal Growth</h3>
          <p className="text-sm text-gray-600">Understand your subconscious mind for better self-awareness</p>
        </div>
        
        <div className="p-4">
          <Compass className="w-6 h-6 text-blue-500 mx-auto mb-3" />
          <h3 className="font-medium text-gray-900 mb-2">Career Guidance</h3>
          <p className="text-sm text-gray-600">Transform dream insights into career development opportunities</p>
        </div>
      </div>
      
      <div className="mt-8">
        <div className="w-12 h-12 mx-auto mb-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
        </div>
        <p className="text-sm text-gray-600">Loading your dream journey...</p>
      </div>
    </div>
  );
}