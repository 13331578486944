import React from 'react';
import { Bell, Mail, Calendar } from 'lucide-react';
import { notificationService } from '../../../lib/notifications/NotificationService';
import { emailService } from '../../../lib/notifications/EmailService';
import { calendarService } from '../../../lib/notifications/CalendarService';
import type { DreamAlarm } from '../../../types/alarms';

interface NotificationSettingsProps {
  alarm: DreamAlarm;
}

export default function NotificationSettings({ alarm }: NotificationSettingsProps) {
  const handleNotificationToggle = async () => {
    try {
      await notificationService.showNotification(alarm);
    } catch (error) {
      console.error('Failed to enable notifications:', error);
    }
  };

  const handleEmailToggle = async () => {
    try {
      await emailService.scheduleEmailReminder(alarm);
    } catch (error) {
      console.error('Failed to schedule email reminder:', error);
    }
  };

  const handleCalendarAdd = async () => {
    try {
      await calendarService.addToCalendar(alarm);
    } catch (error) {
      console.error('Failed to add to calendar:', error);
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-700">Notifications</h3>
      
      <div className="flex gap-3">
        <button
          onClick={handleNotificationToggle}
          className="flex items-center gap-2 px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-50"
        >
          <Bell className="w-4 h-4" />
          <span>Browser</span>
        </button>

        <button
          onClick={handleEmailToggle}
          className="flex items-center gap-2 px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-50"
        >
          <Mail className="w-4 h-4" />
          <span>Email</span>
        </button>

        <button
          onClick={handleCalendarAdd}
          className="flex items-center gap-2 px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-50"
        >
          <Calendar className="w-4 h-4" />
          <span>Calendar</span>
        </button>
      </div>
    </div>
  );
}