import { useState, useEffect, useCallback } from 'react';
import { getUserDreams } from '../lib/api/dreamQueries';
import type { Dream } from '../types/dreams';

export function useDreams(userId: string | undefined) {
  const [dreams, setDreams] = useState<Dream[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchDreams = useCallback(async () => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const data = await getUserDreams(userId);
      setDreams(data);
      setError(null);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to fetch dreams';
      console.error('Error fetching dreams:', { error: err });
      setError(new Error(errorMessage));
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Initial load
  useEffect(() => {
    fetchDreams();
  }, [fetchDreams]);

  const refreshDreams = useCallback(async () => {
    if (!userId) return;
    
    try {
      await fetchDreams();
      return true;
    } catch (err) {
      console.error('Error refreshing dreams:', { error: err });
      return false;
    }
  }, [fetchDreams, userId]);

  return {
    dreams,
    loading,
    error,
    refreshDreams
  };
}