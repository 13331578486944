export const DREAM_ANALYSIS_PROMPT = `Analyze these dreams and provide psychological insights.
Return a valid JSON object with this structure:
{
  "summary": "Overall analysis of dream patterns",
  "patterns": [
    {
      "theme": "Key theme identified",
      "description": "Detailed explanation",
      "significance": "Personal meaning"
    }
  ],
  "recommendations": [
    {
      "title": "Action-oriented title",
      "description": "Detailed explanation",
      "actionSteps": ["Specific steps to take"]
    }
  ]
}`;