import React from 'react';
import { Brain } from 'lucide-react';
import { format } from 'date-fns';
import type { Comment } from '../../lib/comments/types';
import ExpandableContent from './ExpandableContent';
import { useAuth } from '../../contexts/AuthContext';
import SetAlarmButton from '../alarms/SetAlarmButton';

interface AICommentProps {
  comment: Comment;
}

export default function AIComment({ comment }: AICommentProps) {
  const { user } = useAuth();

  return (
    <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-3 mb-4">
        <div className="flex items-center gap-2">
          <Brain className="w-4 h-4 text-indigo-600" />
          <span className="font-medium text-indigo-900">AI Assistant</span>
        </div>
        <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto">
          {comment.dream_id && (
            <div className="w-full sm:w-auto">
              <SetAlarmButton dreamId={comment.dream_id} />
            </div>
          )}
          <span className="text-sm text-indigo-600 whitespace-nowrap">
            {format(new Date(comment.created_at), 'PPp')}
          </span>
        </div>
      </div>
      <div className="prose prose-indigo max-w-none prose-sm sm:prose-base">
        <ExpandableContent content={comment.content} maxLength={1000} />
      </div>
    </div>
  );
}