import React from 'react';
import { Calendar } from 'lucide-react';
import { ALARM_FREQUENCIES } from '../../lib/alarms/constants';
import type { DreamAlarm } from '../../types/alarms';

interface AlarmFrequencySelectProps {
  value: DreamAlarm['frequency'];
  onChange: (frequency: DreamAlarm['frequency']) => void;
}

export default function AlarmFrequencySelect({ value, onChange }: AlarmFrequencySelectProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Repeat Alarm
      </label>
      <div className="mt-2 grid grid-cols-3 gap-3">
        {ALARM_FREQUENCIES.map(({ id, label }) => (
          <button
            key={id}
            type="button"
            onClick={() => onChange(id)}
            className={`flex items-center justify-center px-3 py-2 border rounded-md text-sm
              ${value === id
                ? 'border-indigo-500 text-indigo-700 bg-indigo-50'
                : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
              }`}
          >
            <Calendar className={`w-4 h-4 mr-2 ${
              value === id ? 'text-indigo-500' : 'text-gray-400'
            }`} />
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}