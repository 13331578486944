import React from 'react';
import { X, Globe, Lock, Trash2 } from 'lucide-react';
import { useDream } from '../hooks/useDream';
import LoadingState from './ui/LoadingState';
import ErrorMessage from './ui/ErrorMessage';

interface DreamDetailsProps {
  dreamId: string;
  onClose: () => void;
  onDelete: (dreamId: string) => Promise<void>;
  onVisibilityToggle: (dreamId: string, isPrivate: boolean) => Promise<void>;
  children?: React.ReactNode;
}

export default function DreamDetails({ 
  dreamId, 
  onClose,
  onDelete,
  onVisibilityToggle,
  children
}: DreamDetailsProps) {
  const { dream, loading, error } = useDream(dreamId);

  const handleDelete = async () => {
    if (!dream) return;
    
    try {
      await onDelete(dream.id);
      onClose(); // Close modal after successful deletion
    } catch (error) {
      console.error('Failed to delete dream:', error);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg max-w-2xl w-full p-6">
          <LoadingState message="Loading dream details..." />
        </div>
      </div>
    );
  }

  if (error || !dream) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg max-w-2xl w-full p-6">
          <ErrorMessage message={error?.message || 'Dream not found'} />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white border-b px-6 py-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900">{dream.title}</h2>
          <div className="flex items-center gap-3">
            <button
              onClick={() => onVisibilityToggle(dream.id, !dream.is_private)}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              title={dream.is_private ? 'Make public' : 'Make private'}
            >
              {dream.is_private ? (
                <Lock className="w-5 h-5 text-gray-500" />
              ) : (
                <Globe className="w-5 h-5 text-gray-500" />
              )}
            </button>
            <button
              onClick={handleDelete}
              className="p-2 rounded-full hover:bg-red-100 text-red-600 transition-colors"
              title="Delete dream"
            >
              <Trash2 className="w-5 h-5" />
            </button>
            <button 
              onClick={onClose}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="prose prose-indigo max-w-none">
            <p className="text-gray-700 whitespace-pre-wrap">{dream.content}</p>
          </div>

          {dream.mood && (
            <p className="mt-4 text-sm text-indigo-600">Mood: {dream.mood}</p>
          )}

          {children}
        </div>
      </div>
    </div>
  );
}