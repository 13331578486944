import type { DreamAlarm } from '../../types/alarms';

class AudioService {
  private static instance: AudioService;
  private audioCache: Map<string, HTMLAudioElement> = new Map();
  private isSupported: boolean;

  private constructor() {
    this.isSupported = typeof Audio !== 'undefined';
  }

  static getInstance(): AudioService {
    if (!AudioService.instance) {
      AudioService.instance = new AudioService();
    }
    return AudioService.instance;
  }

  async loadSound(tone: DreamAlarm['tone']): Promise<void> {
    if (!this.isSupported) return;

    const soundUrl = this.getSoundUrl(tone);
    if (this.audioCache.has(soundUrl)) return;

    try {
      const audio = new Audio();
      audio.preload = 'auto';
      
      await new Promise((resolve, reject) => {
        audio.addEventListener('canplaythrough', resolve, { once: true });
        audio.addEventListener('error', reject, { once: true });
        audio.src = soundUrl;
      });

      this.audioCache.set(soundUrl, audio);
    } catch (error) {
      console.warn(`Failed to load sound for tone ${tone}:`, error);
    }
  }

  async playSound(tone: DreamAlarm['tone']): Promise<void> {
    if (!this.isSupported) {
      console.warn('Audio playback is not supported in this environment');
      return;
    }

    const soundUrl = this.getSoundUrl(tone);
    
    try {
      // Load sound if not cached
      if (!this.audioCache.has(soundUrl)) {
        await this.loadSound(tone);
      }

      const audio = this.audioCache.get(soundUrl);
      if (!audio) throw new Error('Sound not loaded');

      // Create a new instance for overlapping playback
      const playbackInstance = audio.cloneNode() as HTMLAudioElement;
      
      await new Promise<void>((resolve, reject) => {
        playbackInstance.addEventListener('ended', () => resolve(), { once: true });
        playbackInstance.addEventListener('error', reject, { once: true });
        
        playbackInstance.play().catch(error => {
          console.warn('Playback failed, falling back to notification sound');
          reject(error);
        });
      });
    } catch (error) {
      console.warn('Error playing alarm sound:', error);
      // Fallback to native notification sound if available
      if ('Notification' in window) {
        new Notification('Dream Alarm', { silent: false });
      }
    }
  }

  private getSoundUrl(tone: DreamAlarm['tone']): string {
    const sounds = {
      calming: '/sounds/calming.mp3',
      energizing: '/sounds/energizing.mp3',
      neutral: '/sounds/neutral.mp3'
    };
    return sounds[tone];
  }
}

// Export singleton instance methods
const audioService = AudioService.getInstance();

export async function preloadAlarmSounds(): Promise<void> {
  try {
    await Promise.all([
      audioService.loadSound('calming'),
      audioService.loadSound('energizing'),
      audioService.loadSound('neutral')
    ]);
  } catch (error) {
    console.warn('Failed to preload alarm sounds:', error);
  }
}

export function playAlarmSound(tone: DreamAlarm['tone']): Promise<void> {
  return audioService.playSound(tone);
}