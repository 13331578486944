import React from 'react';
import { Clock, Video, Users, FileText } from 'lucide-react';

export default function UpcomingSchedule() {
  // Sample schedule data - in real app, this would come from calendar APIs
  const events = [
    {
      id: 1,
      time: '09:00 AM',
      title: 'Team Standup',
      type: 'meeting',
      platform: 'Google Meet',
      icon: <Video className="w-4 h-4" />
    },
    {
      id: 2,
      time: '10:30 AM',
      title: 'Project Review',
      type: 'meeting',
      platform: 'Zoom',
      icon: <Users className="w-4 h-4" />
    },
    {
      id: 3,
      time: '02:00 PM',
      title: 'Documentation Update',
      type: 'task',
      platform: 'Notion',
      icon: <FileText className="w-4 h-4" />
    }
  ];

  return (
    <div className="space-y-4">
      {events.map(event => (
        <div key={event.id} className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg">
          <div className="flex-shrink-0 w-12 text-sm font-medium text-gray-900">
            {event.time}
          </div>
          
          <div className="flex-grow">
            <h3 className="text-sm font-medium text-gray-900">{event.title}</h3>
            <p className="text-xs text-gray-500">{event.platform}</p>
          </div>

          <div className="flex-shrink-0">
            {event.icon}
          </div>
        </div>
      ))}

      <button className="w-full py-2 text-sm text-indigo-600 hover:text-indigo-700 border border-dashed border-indigo-300 rounded-lg hover:border-indigo-400 transition-colors">
        + Add Event
      </button>
    </div>
  );
}