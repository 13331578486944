import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notionAuthService } from '../../lib/services/auth/NotionAuthService';
import LoadingState from '../../components/ui/LoadingState';
import { ErrorAlert } from '../../components/ui/ErrorAlert';

export default function NotionCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      const errorParam = searchParams.get('error');
      const errorDescription = searchParams.get('error_description');

      if (errorParam) {
        console.error('Notion OAuth error:', { error: errorParam, description: errorDescription });
        setError(errorDescription || 'Authorization denied by user');
        return;
      }

      if (!code || !state) {
        console.error('Missing callback parameters:', { code, state });
        setError('Invalid callback parameters');
        return;
      }

      try {
        console.log('Starting Notion callback handling...');
        await notionAuthService.handleCallback(code, state);
        console.log('Notion integration successful');
        navigate('/productivity', { 
          state: { message: 'Successfully connected to Notion' }
        });
      } catch (err) {
        console.error('Notion callback error:', err);
        const errorMessage = err instanceof Error ? err.message : 'Failed to complete Notion integration';
        setError(`${errorMessage}. Please try again or contact support if the issue persists.`);
      }
    };

    handleCallback();
  }, [searchParams, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
        <div className="w-full max-w-md">
          <div className="bg-white rounded-lg shadow-md p-6">
            <ErrorAlert message={error} />
            <div className="mt-6 space-y-4">
              <button
                onClick={() => navigate('/productivity')}
                className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
              >
                Return to Productivity
              </button>
              <button
                onClick={() => notionAuthService.initiateAuth()}
                className="w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <LoadingState message="Completing Notion integration..." />
    </div>
  );
}
