import { useState, useEffect } from 'react';
import { getProfileStats } from '../lib/profile/queries';
import { AuthError } from '../lib/utils/auth';
import type { DreamStatistics } from '../types/profile';

const DEFAULT_STATS: DreamStatistics = {
  totalDreams: 0,
  publicDreams: 0,
  privateDreams: 0,
  totalComments: 0,
  receivedComments: 0,
  givenComments: 0,
  totalReactions: 0,
  receivedReactions: 0,
  givenReactions: 0
};

export function useProfileStats(userId: string | undefined) {
  const [stats, setStats] = useState<DreamStatistics>(DEFAULT_STATS);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    async function loadStats() {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const data = await getProfileStats(userId);
        if (mounted) {
          setStats(data);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          if (err instanceof AuthError) {
            setStats(DEFAULT_STATS);
          } else {
            console.error('Error loading profile stats:', err);
            setError(err instanceof Error ? err : new Error('Failed to load profile statistics'));
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadStats();

    return () => {
      mounted = false;
    };
  }, [userId]);

  return { stats, loading, error };
}