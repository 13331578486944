import { DreamAlarm } from '../../../types/alarms';
import { BaseNotifier } from './BaseNotifier';
import { supabase } from '../../supabase';

export class EmailNotifier extends BaseNotifier {
  async notify(alarm: DreamAlarm): Promise<void> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user?.email) return;

      await this.scheduleEmail(user.email, alarm);
    } catch (error) {
      console.error('Failed to send email notification:', error);
    }
  }

  private async scheduleEmail(email: string, alarm: DreamAlarm): Promise<void> {
    // In a real app, this would call your email service
    console.log('Email scheduled:', {
      to: email,
      subject: alarm.dreams?.title || 'Dream Alarm',
      message: alarm.message,
      time: alarm.time
    });
  }
}