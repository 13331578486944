import { useState, useCallback } from 'react';
import type { CreateDreamData } from '../../types/dreams';
import { validateDreamForm } from '../../lib/forms/validation';

interface UseDreamFormProps {
  onSubmit: (data: CreateDreamData) => Promise<void>;
  initialData?: Partial<CreateDreamData>;
}

export function useDreamForm({ onSubmit, initialData }: UseDreamFormProps) {
  const [formData, setFormData] = useState<CreateDreamData>({
    content: '',
    category: 'sleep',
    is_recurring: false,
    is_private: false,
    ...initialData
  });

  const updateField = useCallback(<K extends keyof CreateDreamData>(
    field: K,
    value: CreateDreamData[K]
  ) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateDreamForm(formData);
    if (validationError) {
      console.error(validationError);
      return;
    }

    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  }, [formData, onSubmit]);

  const resetForm = useCallback(() => {
    setFormData({
      content: '',
      category: 'sleep',
      is_recurring: false,
      is_private: false
    });
  }, []);

  return {
    formData,
    updateField,
    handleSubmit,
    resetForm
  };
}