import { BaseError } from './BaseError';
import type { BaseErrorOptions } from './types';

export class NetworkError extends BaseError {
  constructor(options: BaseErrorOptions) {
    super({
      ...options,
      message: options.message || 'Network connection failed'
    });
  }

  static isNetworkError(error: unknown): boolean {
    return error instanceof TypeError && error.message.includes('Failed to fetch');
  }
}