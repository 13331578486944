import React from 'react';
import { Calendar, CheckSquare, Clock, Brain } from 'lucide-react';
import TasksOverview from './TasksOverview';
import WeeklyTimeline from './WeeklyTimeline';
import AIInsights from './AIInsights';
import UpcomingSchedule from './UpcomingSchedule';
import ServiceIntegration from './ServiceIntegration';

export default function ProductivityDashboard() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Productivity Hub</h1>
          <p className="text-gray-600">Your unified workspace for dreams and tasks</p>
        </div>

        {/* Service Integration Section */}
        <div className="mb-8">
          <ServiceIntegration />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Tasks Overview */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-2">
                <CheckSquare className="w-5 h-5 text-indigo-600" />
                <h2 className="text-lg font-semibold">Tasks Overview</h2>
              </div>
              <select className="text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500">
                <option>Last 7 days</option>
                <option>Last 30 days</option>
                <option>Last 90 days</option>
              </select>
            </div>
            <TasksOverview />
          </div>

          {/* Weekly Timeline */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 mb-6">
              <Clock className="w-5 h-5 text-indigo-600" />
              <h2 className="text-lg font-semibold">Weekly Timeline</h2>
            </div>
            <WeeklyTimeline />
          </div>

          {/* AI Insights */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 mb-6">
              <Brain className="w-5 h-5 text-indigo-600" />
              <h2 className="text-lg font-semibold">AI Insights</h2>
            </div>
            <AIInsights />
          </div>

          {/* Upcoming Schedule */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 mb-6">
              <Calendar className="w-5 h-5 text-indigo-600" />
              <h2 className="text-lg font-semibold">Upcoming Schedule</h2>
            </div>
            <UpcomingSchedule />
          </div>
        </div>
      </div>
    </div>
  );
}