import type { BaseErrorOptions } from './types';

export class BaseError extends Error {
  public readonly code?: string;
  public readonly cause?: unknown;

  constructor(options: BaseErrorOptions) {
    super(options.message);
    this.name = this.constructor.name;
    this.code = options.code;
    this.cause = options.cause;
  }
}