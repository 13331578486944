import React, { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useProfile } from '../hooks/useProfile';
import { useDreams } from '../hooks/useDreams';
import { useProfileStats } from '../hooks/useProfileStats';
import { useDreamInsights } from '../hooks/useDreamInsights';
import ProfileHeader from '../components/profile/ProfileHeader';
import DreamStats from '../components/profile/DreamStats';
import DreamTabs from '../components/profile/DreamTabs';
import DreamAnalysis from '../components/profile/DreamAnalysis/DreamAnalysis';
import EditProfileModal from '../components/profile/EditProfileModal';
import LoadingState from '../components/ui/LoadingState';
import ErrorMessage from '../components/ui/ErrorMessage';
import AnimatedBackground from '../components/animations/AnimatedBackground';

export default function Profile() {
  const { user } = useAuth();
  const { profile, loading: profileLoading, error: profileError, updateProfile } = useProfile(user?.id);
  const { dreams, loading: dreamsLoading, error: dreamsError, refreshDreams } = useDreams(user?.id);
  const { stats, loading: statsLoading, error: statsError } = useProfileStats(user?.id);
  const { insights, loading: insightsLoading, error: insightsError, regenerateInsights } = useDreamInsights(user?.id, dreams || []);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDreamUpdated = useCallback(async () => {
    await refreshDreams();
  }, [refreshDreams]);

  if (profileLoading || dreamsLoading || statsLoading || insightsLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
        <AnimatedBackground type="neural" opacity="low" />
        <div className="max-w-4xl mx-auto px-4 py-4 sm:py-8">
          <LoadingState message="Loading your dream profile..." />
        </div>
      </div>
    );
  }

  if (profileError || dreamsError || statsError) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
        <AnimatedBackground type="neural" opacity="low" />
        <div className="max-w-4xl mx-auto px-4 py-4 sm:py-8">
          <ErrorMessage message="Failed to load profile data. Please try again later." />
        </div>
      </div>
    );
  }

  if (!profile || !stats) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
        <AnimatedBackground type="neural" opacity="low" />
        <div className="max-w-4xl mx-auto px-4 py-4 sm:py-8">
          <ErrorMessage message="Profile not found." />
        </div>
      </div>
    );
  }

  const publicDreams = dreams?.filter(dream => !dream.is_private) || [];
  const privateDreams = dreams?.filter(dream => dream.is_private) || [];

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative">
      <AnimatedBackground type="both" opacity="low" />
      
      <div className="max-w-4xl mx-auto px-4 py-4 sm:py-8 relative z-10">
        <ProfileHeader
          profile={profile}
          onEditProfile={() => setIsEditModalOpen(true)}
        />

        <div className="mt-4 sm:mt-6">
          <DreamStats stats={stats} />
        </div>
        
        <div className="mt-6 sm:mt-8">
          <DreamAnalysis 
            insights={insights}
            error={insightsError}
            loading={insightsLoading}
            onRegenerate={regenerateInsights}
            dreamId={dreams?.[0]?.id}
          />
        </div>

        <div className="mt-6 sm:mt-8">
          <DreamTabs
            publicDreams={publicDreams}
            privateDreams={privateDreams}
            onDreamUpdated={handleDreamUpdated}
          />
        </div>

        {isEditModalOpen && (
          <EditProfileModal
            profile={profile}
            onClose={() => setIsEditModalOpen(false)}
            onSave={updateProfile}
          />
        )}
      </div>
    </div>
  );
}