import type { DreamAlarm } from '../../types/alarms';
import { supabase } from '../supabase';

class EmailService {
  async scheduleEmailReminder(alarm: DreamAlarm): Promise<void> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user?.email) {
        throw new Error('User email not found');
      }

      // Store email preference in user's profile
      await supabase
        .from('profiles')
        .update({
          email_notifications: true,
          notification_email: user.email
        })
        .eq('id', user.id);

      // Note: Email sending would typically be handled by a server-side function
      console.log('Email reminder scheduled:', {
        to: user.email,
        subject: alarm.dreams?.title || 'Dream Alarm',
        message: alarm.message,
        time: alarm.time
      });
    } catch (error) {
      console.error('Failed to schedule email reminder:', error);
      throw error;
    }
  }
}

export const emailService = new EmailService();