import React, { useState } from 'react';
import { Clock, MessageSquare } from 'lucide-react';
import type { CreateAlarmData, DreamAlarm } from '../../../types/alarms';
import TimeInput from '../TimeInput';
import AlarmToneSelect from '../AlarmToneSelect';
import AlarmFrequencySelect from '../AlarmFrequencySelect';
import NotificationSettings from './NotificationSettings';

interface AlarmFormProps {
  initialData?: DreamAlarm;
  onSubmit: (data: CreateAlarmData) => Promise<void>;
  onCancel: () => void;
  loading?: boolean;
  isNewAlarm?: boolean;
}

export default function AlarmForm({ 
  initialData,
  onSubmit,
  onCancel,
  loading = false,
  isNewAlarm = false
}: AlarmFormProps) {
  const [formData, setFormData] = useState<CreateAlarmData>({
    dream_id: initialData?.dream_id,
    message: initialData?.message || '',
    time: initialData?.time || '08:00',
    tone: initialData?.tone || 'neutral',
    frequency: initialData?.frequency || 'once'
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <TimeInput
          value={formData.time}
          onChange={(time) => setFormData(prev => ({ ...prev, time }))}
        />

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Alarm Message
          </label>
          <div className="mt-1 relative">
            <MessageSquare className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <textarea
              id="message"
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows={3}
              required
            />
          </div>
        </div>

        <AlarmToneSelect
          value={formData.tone}
          onChange={(tone) => setFormData(prev => ({ ...prev, tone }))}
        />

        <AlarmFrequencySelect
          value={formData.frequency}
          onChange={(frequency) => setFormData(prev => ({ ...prev, frequency }))}
        />

        <NotificationSettings 
          alarm={{
            ...formData,
            id: initialData?.id || crypto.randomUUID(),
            user_id: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            active: true,
            dreams: initialData?.dreams
          }} 
        />

        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            <Clock className="w-4 h-4" />
            {loading ? 'Saving...' : isNewAlarm ? 'Create Alarm' : 'Update Alarm'}
          </button>
        </div>
      </form>
    </div>
  );
}