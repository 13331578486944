import React, { useState } from 'react';
import { Lock, Globe, MessageCircle, Brain, Moon, Target } from 'lucide-react';
import type { Dream } from '../../types/dreams';
import DreamDetails from '../DreamDetails';
import { useDreamManagement } from '../../hooks/useDreamManagement';
import { ErrorAlert } from '../ui/ErrorAlert';
import DreamComments from '../DreamComments';

interface DreamTabsProps {
  publicDreams: Dream[];
  privateDreams: Dream[];
  onDreamUpdated: (updatedDream?: Dream) => void;
}

export default function DreamTabs({ publicDreams, privateDreams, onDreamUpdated }: DreamTabsProps) {
  const [activeTab, setActiveTab] = useState<'public' | 'private'>('public');
  const [selectedDream, setSelectedDream] = useState<Dream | null>(null);
  const { deleteDream, updateDreamVisibility, error, clearError } = useDreamManagement();

  // const handleDelete = async (dreamId: string) => {
  //   if (!window.confirm('Are you sure you want to delete this dream?')) {
  //     return;
  //   }

  //   try {
  //     await deleteDream(dreamId);
  //     setSelectedDream(null);
  //     onDreamUpdated(); // Refresh the dream list
  //   } catch (error) {
  //     console.error('Failed to delete dream:', error);
  //   }
  // };

  const handleDelete = async (dreamId: string) => {
  console.log('Deleting dream with ID:', dreamId);

  if (!window.confirm('Are you sure you want to delete this dream?')) {
    return;
  }

  try {
    await deleteDream(dreamId);
    console.log('Dream deleted successfully:', dreamId);
    setSelectedDream(null);
    onDreamUpdated(); // Refresh the dream list
  } catch (error) {
    console.error('Failed to delete dream:', error);
  }
};

  
  const handleVisibilityToggle = async (dreamId: string, isPrivate: boolean) => {
    try {
      const updatedDream = await updateDreamVisibility(dreamId, isPrivate);
      onDreamUpdated(updatedDream);
      setSelectedDream(updatedDream);
    } catch (error) {
      console.error('Failed to update dream visibility:', error);
    }
  };

  return (
    <div>
      {error && <ErrorAlert message={error} onClose={clearError} />}
      
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex gap-4 sm:gap-6 overflow-x-auto">
          <button
            onClick={() => setActiveTab('public')}
            className={`py-3 sm:py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap
              ${activeTab === 'public'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <Globe className="w-4 h-4" />
            Public ({publicDreams.length})
          </button>
          <button
            onClick={() => setActiveTab('private')}
            className={`py-3 sm:py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap
              ${activeTab === 'private'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <Lock className="w-4 h-4" />
            Private ({privateDreams.length})
          </button>
        </nav>
      </div>

      <div className="mt-4 space-y-3 sm:space-y-4">
        {(activeTab === 'public' ? publicDreams : privateDreams).map(dream => (
          <div
            key={dream.id}
            onClick={() => setSelectedDream(dream)}
            className="bg-white p-4 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-start gap-4">
              <div className="flex-1 min-w-0">
                <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">{dream.title}</h3>
                <div className="mt-1 sm:mt-2 flex flex-wrap items-center gap-2 sm:gap-4 text-xs sm:text-sm text-gray-600">
                  <div className="flex items-center gap-1">
                    {dream.category === 'sleep' ? (
                      <Moon className="w-3 h-3 sm:w-4 sm:h-4" />
                    ) : (
                      <Target className="w-3 h-3 sm:w-4 sm:h-4" />
                    )}
                    <span>{dream.category === 'sleep' ? 'Dream' : 'Aspiration'}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <MessageCircle className="w-3 h-3 sm:w-4 sm:h-4" />
                    <span>Comments</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Brain className="w-3 h-3 sm:w-4 sm:h-4" />
                    <span>{dream.dream_interpretations ? 'AI Analyzed' : 'Not Analyzed'}</span>
                  </div>
                </div>
              </div>
              {dream.is_private ? (
                <Lock className="w-4 h-4 text-gray-400 flex-shrink-0" />
              ) : (
                <Globe className="w-4 h-4 text-gray-400 flex-shrink-0" />
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedDream && (
        <DreamDetails
          dreamId={selectedDream.id}
          onClose={() => setSelectedDream(null)}
          onDelete={handleDelete}
          onVisibilityToggle={handleVisibilityToggle}
        >
          <DreamComments dreamId={selectedDream.id} dream={selectedDream} />
        </DreamDetails>
      )}
    </div>
  );
}