import { useState, useEffect } from 'react';
import { getComments, createComment } from '../lib/comments/queries';
import type { Comment, CreateCommentData } from '../lib/comments/types';

export function useComments(dreamId: string) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    loadComments();
  }, [dreamId]);

  async function loadComments() {
    try {
      const data = await getComments(dreamId);
      setComments(data);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Error loading comments'));
    } finally {
      setLoading(false);
    }
  }

  async function addComment(content: string) {
    try {
      const commentData: CreateCommentData = {
        dream_id: dreamId,
        content
      };
      const newComment = await createComment(commentData);
      setComments(prev => [...prev, newComment]);
      return newComment;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Error adding comment');
    }
  }

  return {
    comments,
    loading,
    error,
    addComment,
    refreshComments: loadComments
  };
}