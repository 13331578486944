import React from 'react';

export default function NeuralAnimation() {
  return (
    <div className="w-full h-full">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <g className="animate-pulse">
          <circle cx="50" cy="50" r="40" className="stroke-indigo-600 fill-none stroke-[0.5]" />
          <circle cx="50" cy="50" r="35" className="stroke-indigo-500 fill-none stroke-[0.5]" />
          <circle cx="50" cy="50" r="30" className="stroke-indigo-400 fill-none stroke-[0.5]" />
        </g>
        <g className="animate-[pulse_3s_ease-in-out_infinite]">
          <line x1="20" y1="50" x2="80" y2="50" className="stroke-indigo-300 stroke-[0.5]" />
          <line x1="50" y1="20" x2="50" y2="80" className="stroke-indigo-300 stroke-[0.5]" />
          <line x1="30" y1="30" x2="70" y2="70" className="stroke-indigo-300 stroke-[0.5]" />
          <line x1="30" y1="70" x2="70" y2="30" className="stroke-indigo-300 stroke-[0.5]" />
        </g>
        <g className="animate-[bounce_2s_ease-in-out_infinite]">
          <circle cx="20" cy="50" r="2" className="fill-indigo-500" />
          <circle cx="80" cy="50" r="2" className="fill-indigo-500" />
          <circle cx="50" cy="20" r="2" className="fill-indigo-500" />
          <circle cx="50" cy="80" r="2" className="fill-indigo-500" />
          <circle cx="30" cy="30" r="2" className="fill-indigo-500" />
          <circle cx="70" cy="70" r="2" className="fill-indigo-500" />
          <circle cx="30" cy="70" r="2" className="fill-indigo-500" />
          <circle cx="70" cy="30" r="2" className="fill-indigo-500" />
        </g>
      </svg>
    </div>
  );
}