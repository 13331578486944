import React from 'react';
import { Book, MessageSquare, Heart } from 'lucide-react';
import type { DreamStatistics } from '../../types/profile';

interface DreamStatsProps {
  stats: DreamStatistics;
}

export default function DreamStats({ stats }: DreamStatsProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex items-center gap-2 mb-2">
          <Book className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600" />
          <h3 className="font-medium text-gray-900">Dreams</h3>
        </div>
        <p className="text-xl sm:text-2xl font-bold text-gray-900">{stats.totalDreams}</p>
        <p className="text-xs sm:text-sm text-gray-500 mt-1">
          {stats.privateDreams} private · {stats.publicDreams} public
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex items-center gap-2 mb-2">
          <MessageSquare className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600" />
          <h3 className="font-medium text-gray-900">Comments</h3>
        </div>
        <p className="text-xl sm:text-2xl font-bold text-gray-900">{stats.totalComments}</p>
        <p className="text-xs sm:text-sm text-gray-500 mt-1">
          {stats.receivedComments} received · {stats.givenComments} given
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex items-center gap-2 mb-2">
          <Heart className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600" />
          <h3 className="font-medium text-gray-900">Reactions</h3>
        </div>
        <p className="text-xl sm:text-2xl font-bold text-gray-900">{stats.totalReactions}</p>
        <p className="text-xs sm:text-sm text-gray-500 mt-1">
          {stats.receivedReactions} received · {stats.givenReactions} given
        </p>
      </div>
    </div>
  );
}