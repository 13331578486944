import React, { useCallback } from 'react';
import { Mic, MicOff } from 'lucide-react';
import { useVoiceInput } from '../../hooks/useVoiceInput';

interface VoiceInputProps {
  onTextChange: (text: string) => void;
  disabled?: boolean;
}

export default function VoiceInput({ onTextChange, disabled }: VoiceInputProps) {
  const { isListening, error, startListening } = useVoiceInput();
  const [recognition, setRecognition] = React.useState<any>(null);

  const handleToggleVoice = useCallback(() => {
    if (isListening && recognition) {
      recognition.stop();
      setRecognition(null);
    } else {
      const newRecognition = startListening(onTextChange);
      if (newRecognition) {
        setRecognition(newRecognition);
      }
    }
  }, [isListening, recognition, startListening, onTextChange]);

  return (
    <div className="relative inline-block">
      <button
        type="button"
        onClick={handleToggleVoice}
        disabled={disabled}
        className={`p-2 rounded-full transition-colors duration-200 ${
          isListening 
            ? 'bg-red-100 text-red-600 hover:bg-red-200' 
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        } disabled:opacity-50 disabled:cursor-not-allowed`}
        title={isListening ? 'Stop recording' : 'Start voice input'}
      >
        {isListening ? (
          <MicOff className="w-5 h-5 animate-pulse" />
        ) : (
          <Mic className="w-5 h-5" />
        )}
      </button>
      
      {error && (
        <div className="absolute bottom-full mb-2 right-0 bg-red-50 text-red-600 text-xs p-2 rounded-md whitespace-nowrap shadow-lg">
          {error}
        </div>
      )}
    </div>
  );
}