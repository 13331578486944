import { BaseError } from './BaseError';
import type { BaseErrorOptions } from './types';

export class SupabaseError extends BaseError {
  constructor(options: BaseErrorOptions) {
    super({
      ...options,
      message: options.message || 'Supabase operation failed'
    });
  }
}