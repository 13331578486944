import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Brain, Target, Moon, Calendar, Sparkles } from 'lucide-react';

export default function Hero() {
  const navigate = useNavigate();

  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center">
              <div className="flex justify-center items-center mb-8 space-x-4">
                <div className="relative">
                  <Moon className="w-16 h-16 text-indigo-600 animate-pulse" />
                  <div className="absolute -top-2 -right-2 w-6 h-6 bg-indigo-200 rounded-full animate-ping" />
                </div>
                <div className="relative">
                  <Brain className="w-20 h-20 text-indigo-600" />
                  <div className="absolute -top-2 -right-2 w-6 h-6 bg-yellow-400 rounded-full animate-bounce" />
                </div>
                <div className="relative">
                  <Target className="w-16 h-16 text-indigo-600 animate-pulse" />
                  <div className="absolute -top-2 -right-2 w-6 h-6 bg-indigo-200 rounded-full animate-ping" />
                </div>
              </div>
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Unlock Your Dream Life</span>
                <span className="block text-indigo-600">Through Conscious & Subconscious Wisdom</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Harness the power of your dreams - both sleeping and aspirational - while supercharging your productivity with seamless integrations to your favorite tools.
              </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <button
                  onClick={() => navigate('/register')}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 transition-all duration-200 hover:scale-105"
                >
                  <Brain className="w-5 h-5 mr-2" />
                  Start Your Journey
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}