import { DreamAlarm } from '../../../types/alarms';
import { NotificationManager } from './NotificationManager';
import { playAlarmSound } from '../sound';

export class AlarmManager {
  private static instance: AlarmManager;
  private activeAlarms: Map<string, NodeJS.Timeout> = new Map();
  private notificationManager: NotificationManager;

  private constructor() {
    this.notificationManager = NotificationManager.getInstance();
  }

  static getInstance(): AlarmManager {
    if (!AlarmManager.instance) {
      AlarmManager.instance = new AlarmManager();
    }
    return AlarmManager.instance;
  }

  scheduleAlarm(alarm: DreamAlarm): void {
    this.clearAlarm(alarm.id);
    if (!alarm.active) return;

    const nextTime = this.getNextAlarmTime(alarm);
    const delay = nextTime.getTime() - Date.now();
    if (delay <= 0) return;

    const timerId = setTimeout(async () => {
      await this.triggerAlarm(alarm);
      if (alarm.frequency === 'daily' || alarm.frequency === 'weekly') {
        this.scheduleAlarm(alarm);
      }
    }, delay);

    this.activeAlarms.set(alarm.id, timerId);
  }

  clearAlarm(alarmId: string): void {
    const timerId = this.activeAlarms.get(alarmId);
    if (timerId) {
      clearTimeout(timerId);
      this.activeAlarms.delete(alarmId);
    }
  }

  private getNextAlarmTime(alarm: DreamAlarm): Date {
    const [hours, minutes] = alarm.time.split(':').map(Number);
    const next = new Date();
    next.setHours(hours, minutes, 0, 0);

    if (next <= new Date()) {
      switch (alarm.frequency) {
        case 'daily':
          next.setDate(next.getDate() + 1);
          break;
        case 'weekly':
          next.setDate(next.getDate() + 7);
          break;
        default:
          next.setDate(next.getDate() + 1);
      }
    }

    return next;
  }

  private async triggerAlarm(alarm: DreamAlarm): Promise<void> {
    try {
      // Play sound first
      await playAlarmSound(alarm.tone);
      
      // Then show notifications
      await this.notificationManager.notify(alarm);
    } catch (error) {
      console.error('Failed to trigger alarm:', error);
    }
  }
}