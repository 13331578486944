import React from 'react';
import { MessageSquare } from 'lucide-react';

interface MessageInputProps {
  value: string;
  onChange: (message: string) => void;
}

export default function MessageInput({ value, onChange }: MessageInputProps) {
  return (
    <div>
      <label htmlFor="alarm-message" className="block text-sm font-medium text-gray-700 mb-1">
        Alarm Message
      </label>
      <div className="relative">
        <MessageSquare className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
        <textarea
          id="alarm-message"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="What would you like to remember?"
          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base sm:text-sm"
          rows={3}
          required
        />
      </div>
    </div>
  );
}