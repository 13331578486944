import React from 'react';

export default function WeeklyTimeline() {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  
  // Sample data - in real app, this would come from API
  const timelineData = {
    meetings: [4, 2, 3, 5, 1, 0, 0],
    tasks: [8, 6, 7, 4, 5, 2, 1]
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between">
        {days.map((day, index) => (
          <div key={day} className="text-center">
            <div className="text-sm font-medium text-gray-900">{day}</div>
            <div className="mt-1 space-y-1">
              <div className="h-20 flex flex-col justify-end">
                <div 
                  className="w-8 bg-indigo-200 rounded-t transition-all duration-300 hover:bg-indigo-300"
                  style={{ height: `${timelineData.meetings[index] * 10}%` }}
                  title={`${timelineData.meetings[index]} meetings`}
                ></div>
              </div>
              <div className="h-20 flex flex-col justify-end">
                <div 
                  className="w-8 bg-purple-200 rounded-t transition-all duration-300 hover:bg-purple-300"
                  style={{ height: `${timelineData.tasks[index] * 5}%` }}
                  title={`${timelineData.tasks[index]} tasks`}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-4">
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-indigo-200 rounded"></div>
          <span className="text-sm text-gray-600">Meetings</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-purple-200 rounded"></div>
          <span className="text-sm text-gray-600">Tasks</span>
        </div>
      </div>
    </div>
  );
}