import React, { useState } from 'react';
import { X } from 'lucide-react';
import AlarmForm from './AlarmForm';
import type { CreateAlarmData } from '../../../types/alarms';

interface CreateAlarmModalProps {
  onClose: () => void;
  onSubmit: (data: CreateAlarmData) => Promise<void>;
}

export default function CreateAlarmModal({ onClose, onSubmit }: CreateAlarmModalProps) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: CreateAlarmData) => {
    setLoading(true);
    try {
      await onSubmit(data);
      onClose();
    } catch (error) {
      console.error('Error creating alarm:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-900">Create New Alarm</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <AlarmForm
          onSubmit={handleSubmit}
          onCancel={onClose}
          loading={loading}
          isNewAlarm
        />
      </div>
    </div>
  );
}