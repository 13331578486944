import type { DreamAlarm } from '../../types/alarms';

class CalendarService {
  async addToCalendar(alarm: DreamAlarm): Promise<void> {
    const startTime = this.parseAlarmTime(alarm.time);
    const endTime = new Date(startTime.getTime() + 30 * 60000); // 30 minutes duration

    const event = {
      title: alarm.dreams?.title || 'Dream Alarm',
      description: alarm.message,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      recurrence: this.getRecurrenceRule(alarm.frequency)
    };

    // Generate Google Calendar URL
    const googleUrl = this.createGoogleCalendarUrl(event);
    
    // Open calendar URL in new window
    window.open(googleUrl, '_blank');
  }

  private parseAlarmTime(time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  private getRecurrenceRule(frequency?: string): string {
    switch (frequency) {
      case 'daily':
        return 'RRULE:FREQ=DAILY';
      case 'weekly':
        return 'RRULE:FREQ=WEEKLY';
      default:
        return '';
    }
  }

  private createGoogleCalendarUrl(event: {
    title: string;
    description: string;
    startTime: string;
    endTime: string;
    recurrence: string;
  }): string {
    const params = new URLSearchParams({
      action: 'TEMPLATE',
      text: event.title,
      details: event.description,
      dates: `${event.startTime}/${event.endTime}`.replace(/[-:]/g, ''),
      recur: event.recurrence
    });

    return `https://calendar.google.com/calendar/render?${params.toString()}`;
  }
}

export const calendarService = new CalendarService();