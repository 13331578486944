import { supabase } from '../supabase';
import { withRetry } from '../utils/retry';
import { handleSupabaseError, handleDatabaseError } from '../utils/errors';
import type { Comment, CreateCommentData } from './types';

export async function getComments(dreamId: string): Promise<Comment[]> {
  return withRetry(async () => {
    try {
      const { data, error } = await supabase
        .from('dream_comments')
        .select(`
          id,
          dream_id,
          user_id,
          content,
          created_at,
          is_ai,
          ai_model,
          profiles!dream_comments_profile_id_fkey (
            id,
            username,
            public_name,
            display_name
          )
        `)
        .eq('dream_id', dreamId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      return data || [];
    } catch (error) {
      throw handleSupabaseError(error);
    }
  });
}

export async function createComment(commentData: CreateCommentData): Promise<Comment> {
  return withRetry(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Must be logged in to comment');

      const { data, error } = await supabase
        .from('dream_comments')
        .insert({
          dream_id: commentData.dream_id,
          user_id: user.id,
          content: commentData.content,
          is_ai: commentData.is_ai || false,
          ai_model: commentData.ai_model
        })
        .select(`
          id,
          dream_id,
          user_id,
          content,
          created_at,
          is_ai,
          ai_model,
          profiles!dream_comments_profile_id_fkey (
            id,
            username,
            public_name,
            display_name
          )
        `)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      throw handleSupabaseError(error);
    }
  });
}