import { supabase } from '../supabase';
import type { User } from '@supabase/supabase-js';

export class AuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthError';
  }

  static isAuthError(error: unknown): error is AuthError {
    return error instanceof AuthError;
  }
}

export async function getCurrentUser(): Promise<User | null> {
  try {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) throw new AuthError(error.message);
    return user;
  } catch (error) {
    if (error instanceof AuthError) throw error;
    console.debug('No authenticated user found');
    return null;
  }
}

export async function requireAuth(): Promise<User> {
  const user = await getCurrentUser();
  if (!user) {
    throw new AuthError('Authentication required');
  }
  return user;
}