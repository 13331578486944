import React, { useState } from 'react';
import { X, User, Brain } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { Profile } from '../../types/profile';
import { uploadProfileImage } from '../../lib/profile/storage';
import ImageUpload from './ImageUpload';
import NeuralAnimation from '../animations/NeuralAnimation';

interface EditProfileModalProps {
  profile: Profile;
  onClose: () => void;
  onSave: (data: Partial<Profile>) => Promise<void>;
}

export default function EditProfileModal({ profile, onClose, onSave }: EditProfileModalProps) {
  const [formData, setFormData] = useState({
    public_name: profile.public_name || '',
    bio: profile.bio || ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleImageUpload = async (file: File) => {
    try {
      setLoading(true);
      const avatarUrl = await uploadProfileImage(file, profile.id);
      await onSave({ avatar_url: avatarUrl });
    } catch (err) {
      setError('Failed to upload image');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await onSave(formData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full overflow-hidden relative">
        <div className="absolute inset-0 opacity-5 pointer-events-none">
          <NeuralAnimation />
        </div>

        <div className="relative z-10">
          <div className="flex justify-between items-center p-6 border-b">
            <div className="flex items-center gap-2">
              <Brain className="w-5 h-5 text-indigo-600" />
              <h2 className="text-xl font-bold text-gray-900">Edit Profile</h2>
            </div>
            <button 
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {error && (
            <div className="mx-6 mt-4 p-3 bg-red-50 text-red-700 rounded-md">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div className="flex justify-center">
              <div className="relative">
                <div className="w-24 h-24 rounded-full bg-indigo-100 flex items-center justify-center overflow-hidden">
                  {profile.avatar_url ? (
                    <img 
                      src={profile.avatar_url} 
                      alt={profile.public_name || 'Profile'} 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <User className="w-12 h-12 text-indigo-600" />
                  )}
                </div>
                <ImageUpload onFileSelect={handleImageUpload} disabled={loading} />
              </div>
            </div>

            <div>
              <label htmlFor="public_name" className="block text-sm font-medium text-gray-700">
                Public Name
              </label>
              <div className="mt-1 relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  id="public_name"
                  value={formData.public_name}
                  onChange={e => setFormData(prev => ({ ...prev, public_name: e.target.value }))}
                  className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                id="bio"
                value={formData.bio}
                onChange={e => setFormData(prev => ({ ...prev, bio: e.target.value }))}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Tell us about yourself..."
              />
            </div>

            <div className="border-t pt-6">
              <p className="text-sm text-gray-600">
                Want to change your password?{' '}
                <Link 
                  to="/reset-password"
                  className="text-indigo-600 hover:text-indigo-500"
                  onClick={onClose}
                >
                  Reset it here
                </Link>
              </p>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 transition-colors"
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}