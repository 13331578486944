import React, { useState } from 'react';
import { Bell } from 'lucide-react';
import { useAlarms } from '../../hooks/useAlarms';
import AlarmModal from './AlarmModal';
import type { CreateAlarmData } from '../../types/alarms';

interface SetAlarmButtonProps {
  dreamId: string;
  className?: string;
}

export default function SetAlarmButton({ dreamId, className = '' }: SetAlarmButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const { addAlarm } = useAlarms();

  const handleSubmit = async (data: CreateAlarmData) => {
    if (!addAlarm) return;
    
    try {
      await addAlarm(data);
      setShowModal(false);
    } catch (error) {
      console.error('Failed to create alarm:', error);
      throw error;
    }
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={`flex items-center gap-2 px-3 py-1.5 text-sm font-medium rounded-full
          bg-indigo-100 text-indigo-700 hover:bg-indigo-200 transition-colors ${className}`}
      >
        <Bell className="w-4 h-4" />
        Set Alarm
      </button>

      {showModal && (
        <AlarmModal
          dreamId={dreamId}
          onClose={() => setShowModal(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}