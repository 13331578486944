import { calculateBackoff } from './backoff';
import type { RetryOptions } from './types';
import { AIServiceError } from '../errors/AIServiceError';

const DEFAULT_OPTIONS: Required<RetryOptions> = {
  maxRetries: 3,
  baseDelay: 1000,
  maxDelay: 10000,
  shouldRetry: (error: Error) => 
    error instanceof AIServiceError && error.retryable
};

export async function withRetry<T>(
  operation: () => Promise<T>,
  options: RetryOptions = {}
): Promise<T> {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  let lastError: Error;
  
  for (let attempt = 0; attempt < opts.maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      
      if (!opts.shouldRetry(lastError) || attempt === opts.maxRetries - 1) {
        throw lastError;
      }
      
      const delay = calculateBackoff(attempt, opts.baseDelay, opts.maxDelay);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  
  throw lastError!;
}