import React from 'react';
import { Sparkles } from 'lucide-react';
import type { CreateDreamData } from '../../types/dreams';
import DreamCategorySelect from './DreamCategorySelect';
import DreamContentInput from './DreamContentInput';
import RecurringDreamToggle from './RecurringDreamToggle';
import PrivacyToggle from './PrivacyToggle';

interface DreamFormProps {
  formData: CreateDreamData;
  onUpdateField: <K extends keyof CreateDreamData>(field: K, value: CreateDreamData[K]) => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  loading: boolean;
  isAnalyzing: boolean;
}

export default function DreamForm({
  formData,
  onUpdateField,
  onSubmit,
  loading,
  isAnalyzing
}: DreamFormProps) {
  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <DreamCategorySelect
        value={formData.category}
        onChange={category => onUpdateField('category', category)}
      />

      <DreamContentInput
        value={formData.content}
        onChange={content => onUpdateField('content', content)}
        category={formData.category}
      />

      {formData.category === 'sleep' && (
        <RecurringDreamToggle
          value={formData.is_recurring || false}
          onChange={value => onUpdateField('is_recurring', value)}
        />
      )}

      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <PrivacyToggle
          value={formData.is_private}
          onChange={value => onUpdateField('is_private', value)}
        />

        <button
          type="submit"
          disabled={loading || !formData.content.trim()}
          className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 transition-colors"
        >
          <Sparkles className={`w-4 h-4 ${isAnalyzing ? 'animate-spin' : ''}`} />
          {loading ? 'Processing...' : formData.category === 'sleep' ? 'Share Dream' : 'Create Plan'}
        </button>
      </div>
    </form>
  );
}