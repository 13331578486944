import { useState, useEffect } from 'react';
import { getDream } from '../lib/api/dreams';
import type { Dream } from '../types/dreams';

export function useDream(dreamId: string | undefined) {
  const [dream, setDream] = useState<Dream | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function loadDream() {
      if (!dreamId) {
        setLoading(false);
        return;
      }

      try {
        const data = await getDream(dreamId);
        setDream(data);
        setError(null);
      } catch (err) {
        console.error('Error loading dream:', err);
        setError(err instanceof Error ? err : new Error('Failed to load dream'));
      } finally {
        setLoading(false);
      }
    }

    loadDream();
  }, [dreamId]);

  return { dream, loading, error };
}