import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getUserAlarms } from '../lib/alarms/queries';
import { AuthError } from '../lib/utils/auth';
import { AlarmManager } from '../lib/alarms/core/AlarmManager';
import type { DreamAlarm } from '../types/alarms';

export function useAlarms() {
  const { user } = useAuth();
  const [alarms, setAlarms] = useState<DreamAlarm[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const alarmManager = AlarmManager.getInstance();

  useEffect(() => {
    let mounted = true;

    async function loadAlarms() {
      if (!user) {
        if (mounted) {
          setAlarms([]);
          setLoading(false);
        }
        return;
      }

      setLoading(true);
      try {
        const data = await getUserAlarms();
        if (mounted) {
          setAlarms(data);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          if (err instanceof AuthError) {
            setAlarms([]);
          } else {
            console.error('Failed to load alarms:', err);
            setError(err instanceof Error ? err : new Error('Failed to load alarms'));
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadAlarms();

    return () => {
      mounted = false;
    };
  }, [user]);

  // Schedule active alarms
  useEffect(() => {
    if (!user) return;

    alarms.forEach(alarm => {
      if (alarm.active) {
        alarmManager.scheduleAlarm(alarm);
      }
    });

    return () => {
      alarms.forEach(alarm => {
        alarmManager.clearAlarm(alarm.id);
      });
    };
  }, [alarms, user]);

  return {
    alarms,
    loading,
    error,
    addAlarm: async () => {}, // Implementation omitted for brevity
    toggleAlarm: async () => {}, // Implementation omitted for brevity
    removeAlarm: async () => {}, // Implementation omitted for brevity
    refreshAlarms: async () => {} // Implementation omitted for brevity
  };
}