import { useState, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { DreamError } from '../lib/utils/errors/DreamError';
import type { Dream } from '../types/dreams';

export function useDreamManagement() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // const handleDelete = useCallback(async (dreamId: string): Promise<void> => {
  //   if (!dreamId || !user) {
  //     throw new DreamError('Invalid request - missing dream ID or user');
  //   }

  //   setLoading(true);
  //   setError(null);
    
  //   try {
  //     // First verify user owns the dream
  //     const { data: dream, error: fetchError } = await supabase
  //       .from('dreams')
  //       .select('id, user_id')
  //       .eq('id', dreamId)
  //       .single();

  //     if (fetchError) throw fetchError;
  //     if (!dream) throw new DreamError('Dream not found');
  //     if (dream.user_id !== user.id) throw new DreamError('Access denied');

  //     // Delete the dream - cascade will handle related records
  //     const { error: deleteError } = await supabase
  //       .from('dreams')
  //       .delete()
  //       .eq('id', dreamId)
  //       .eq('user_id', user.id); // Extra safety check

  //     if (deleteError) throw deleteError;
  //   } catch (err) {
  //     console.error('Dream deletion failed:', err);
  //     setError(err instanceof Error ? err.message : 'Failed to delete dream');
  //     throw err;
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [user]);

  const handleDelete = useCallback(async (dreamId: string): Promise<void> => {
  if (!dreamId || !user) {
    console.error('Invalid request - missing dream ID or user', { dreamId, user });
    throw new DreamError('Invalid request - missing dream ID or user');
  }

  setLoading(true);
  setError(null);

  try {
    console.log('Attempting to delete dream:', { dreamId, userId: user.id });

    const { data: dream, error: fetchError } = await supabase
      .from('dreams')
      .select('id, user_id')
      .eq('id', dreamId)
      .single();

    if (fetchError) {
      console.error('Error fetching dream:', fetchError);
      throw fetchError;
    }

    if (!dream) {
      console.error('Dream not found:', dreamId);
      throw new DreamError('Dream not found');
    }

    if (dream.user_id !== user.id) {
      console.error('Access denied:', { dreamId, userId: user.id });
      throw new DreamError('Access denied');
    }

    const { error: deleteError } = await supabase
      .from('dreams')
      .delete()
      .eq('id', dreamId)
      .eq('user_id', user.id);

    if (deleteError) {
      console.error('Error deleting dream:', deleteError);
      throw deleteError;
    }

    console.log('Dream successfully deleted:', dreamId);
  } catch (err) {
    console.error('Dream deletion failed:', err);
    setError(err instanceof Error ? err.message : 'Failed to delete dream');
    throw err;
  } finally {
    setLoading(false);
  }
}, [user]);

  
  const handleVisibilityUpdate = useCallback(async (dreamId: string, isPrivate: boolean): Promise<Dream> => {
    if (!dreamId || !user) {
      throw new DreamError('Invalid request - missing dream ID or user');
    }

    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from('dreams')
        .update({ is_private: isPrivate })
        .eq('id', dreamId)
        .eq('user_id', user.id) // Ensure user owns the dream
        .select(`
          *,
          dream_interpretations (
            interpretation,
            symbols_analysis,
            is_plan
          )
        `)
        .single();

      if (error) throw error;
      if (!data) throw new DreamError('Dream not found');

      return data;
    } catch (err) {
      console.error('Dream visibility update failed:', err);
      setError(err instanceof Error ? err.message : 'Failed to update dream visibility');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [user]);

  return {
    deleteDream: handleDelete,
    updateDreamVisibility: handleVisibilityUpdate,
    loading,
    error,
    clearError: () => setError(null)
  };
}