import { DreamAlarm } from '../../../types/alarms';
import { BaseNotifier } from './BaseNotifier';

export class BrowserNotifier extends BaseNotifier {
  private hasPermission = false;

  constructor() {
    super();
    this.checkPermission();
  }

  private async checkPermission(): Promise<void> {
    if (!('Notification' in window)) return;

    if (Notification.permission === 'granted') {
      this.hasPermission = true;
    } else if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      this.hasPermission = permission === 'granted';
    }
  }

  async notify(alarm: DreamAlarm): Promise<void> {
    if (!this.hasPermission) {
      await this.checkPermission();
      if (!this.hasPermission) return;
    }

    const notification = new Notification(this.getTitle(alarm), {
      body: alarm.message,
      icon: '/vite.svg',
      tag: `dream-alarm-${alarm.id}`,
      requireInteraction: true,
      actions: [
        { action: 'snooze', title: 'Snooze 5m' },
        { action: 'dismiss', title: 'Dismiss' }
      ]
    });

    this.setupNotificationHandlers(notification, alarm);
  }

  private setupNotificationHandlers(notification: Notification, alarm: DreamAlarm): void {
    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
    });

    notification.addEventListener('action', (event) => {
      if (event instanceof NotificationEvent) {
        if (event.action === 'snooze') {
          setTimeout(() => this.notify(alarm), 5 * 60 * 1000);
        }
        notification.close();
      }
    });
  }

  private getTitle(alarm: DreamAlarm): string {
    return alarm.dreams?.title 
      ? `Dream Alarm: ${alarm.dreams.title}`
      : 'Dream Alarm';
  }
}