import React from 'react';
import { Brain } from 'lucide-react';

interface InsightCardProps {
  theme: string;
  description: string;
  significance: string;
}

export default function InsightCard({ theme, description, significance }: InsightCardProps) {
  return (
    <div className="bg-indigo-50 p-4 rounded-lg">
      <div className="flex items-center gap-2 mb-2">
        <Brain className="w-4 h-4 text-indigo-600 flex-shrink-0" />
        <h4 className="font-medium text-indigo-900 text-sm sm:text-base">{theme}</h4>
      </div>
      <p className="text-sm text-indigo-700 leading-relaxed mb-2">{description}</p>
      <p className="text-xs text-indigo-600 italic">{significance}</p>
    </div>
  );
}