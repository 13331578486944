import React from 'react';
import type { Components } from 'react-markdown';

export const markdownComponents: Components = {
  h1: ({node, ...props}) => (
    <h1 className="text-xl sm:text-2xl font-bold mt-6 mb-3" {...props} />
  ),
  h2: ({node, ...props}) => (
    <h2 className="text-lg sm:text-xl font-bold mt-5 mb-2" {...props} />
  ),
  h3: ({node, ...props}) => (
    <h3 className="text-base sm:text-lg font-bold mt-4 mb-2" {...props} />
  ),
  p: ({node, ...props}) => (
    <p className="text-sm sm:text-base mb-3 leading-relaxed" {...props} />
  ),
  ul: ({node, ...props}) => (
    <ul className="list-disc ml-4 sm:ml-6 mb-3 text-sm sm:text-base" {...props} />
  ),
  ol: ({node, ...props}) => (
    <ol className="list-decimal ml-4 sm:ml-6 mb-3 text-sm sm:text-base" {...props} />
  ),
  li: ({node, ...props}) => (
    <li className="mb-1 text-sm sm:text-base leading-relaxed" {...props} />
  ),
  code: ({node, inline, ...props}) => 
    inline ? (
      <code className="bg-gray-100 px-1 py-0.5 rounded text-sm" {...props} />
    ) : (
      <code className="block bg-gray-100 p-3 rounded-md mb-3 text-sm overflow-x-auto" {...props} />
    ),
  blockquote: ({node, ...props}) => (
    <blockquote className="border-l-4 border-indigo-200 pl-4 my-3 italic text-sm sm:text-base" {...props} />
  ),
};