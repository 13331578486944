import React from 'react';
import { Brain, RefreshCw } from 'lucide-react';
import type { DreamInsight } from '../../../types/dreams';
import { AIServiceError } from '../../../lib/ai/errors/AIServiceError';
import InsightCard from './InsightCard';
import RecommendationCard from './RecommendationCard';
import LoadingState from '../../ui/LoadingState';
import ErrorState from '../../ui/ErrorState';
import SetAlarmButton from '../../alarms/SetAlarmButton';

interface DreamAnalysisProps {
  insights: DreamInsight | null;
  dreamId: string;
  error?: Error | null;
  loading?: boolean;
  onRegenerate?: () => void;
}

export default function DreamAnalysis({
  insights,
  dreamId,
  error,
  loading = false,
  onRegenerate
}: DreamAnalysisProps) {
  if (loading) {
    return <LoadingState message="Analyzing your dreams..." />;
  }

  if (error) {
    return (
      <ErrorState
        title="What Your Dreams Are Telling You"
        message={
          error instanceof AIServiceError && error.code === 'RATE_LIMIT'
            ? 'Service is temporarily busy. Please try again in a few minutes.'
            : 'Unable to generate insights right now. Please try again later.'
        }
      />
    );
  }

  if (!insights) {
    return (
      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex items-center gap-3 mb-4">
          <Brain className="w-6 h-6 text-gray-400" />
          <h2 className="text-lg sm:text-xl font-bold text-gray-600">What Your Dreams Are Telling You</h2>
        </div>
        <p className="text-sm sm:text-base text-gray-500 leading-relaxed">
          Share your dreams to receive personalized insights into your subconscious mind.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-4 mb-6">
        <div className="flex items-center gap-3">
          <Brain className="w-6 h-6 text-indigo-600" />
          <h2 className="text-lg sm:text-xl font-bold text-gray-900">What Your Dreams Are Telling You</h2>
        </div>
        <div className="flex items-center gap-2 w-full sm:w-auto">
          <div className="w-full sm:w-auto">
            <SetAlarmButton dreamId={dreamId} />
          </div>
          {onRegenerate && (
            <button
              onClick={onRegenerate}
              disabled={loading}
              className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-700 disabled:opacity-50"
            >
              <RefreshCw className={`w-4 h-4 ${loading ? 'animate-spin' : ''}`} />
              {loading ? 'Analyzing...' : 'Re-analyze'}
            </button>
          )}
        </div>
      </div>

      <div className="space-y-8">
        <div>
          <h3 className="text-base sm:text-lg font-medium text-gray-900 mb-3">Summary</h3>
          <p className="text-sm sm:text-base text-gray-600 leading-relaxed">{insights.summary}</p>
        </div>

        <div>
          <h3 className="text-base sm:text-lg font-medium text-gray-900 mb-3">Patterns & Themes</h3>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
            {insights.patterns.map((pattern, index) => (
              <InsightCard
                key={index}
                theme={pattern.theme}
                description={pattern.description}
                significance={pattern.significance}
              />
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-base sm:text-lg font-medium text-gray-900 mb-3">Recommendations</h3>
          <div className="space-y-6">
            {insights.recommendations.map((recommendation, index) => (
              <RecommendationCard
                key={index}
                title={recommendation.title}
                description={recommendation.description}
                actionSteps={recommendation.actionSteps}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}