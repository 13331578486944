import { useState, useEffect, useCallback } from 'react';
import { getUserDreams } from '../lib/api/dreamQueries';
import type { Dream } from '../types/dreams';

export function useDreamFeed(userId?: string) {
  const [dreams, setDreams] = useState<Dream[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchDreams = useCallback(async () => {
    if (!userId) return;

    try {
      setLoading(true);
      const data = await getUserDreams(userId);
      setDreams(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching dreams:', err);
      setError(err instanceof Error ? err : new Error('Error fetching dreams'));
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Initial load
  useEffect(() => {
    fetchDreams();
  }, [fetchDreams]);

  return { 
    dreams, 
    loading, 
    error,
    refreshDreams: fetchDreams 
  };
}